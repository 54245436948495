import axios from 'axios';
import webStorage from '@/utils/webStorage';
import router from '@/router';
import store from '@/store';
let fileName = '';
// store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
const listUrlDisableLoading = ['/branch-attribute', '/info', '/refresh-token', '/notification']
const isLoadingFullscreen = (config) => {
  return (['/workflow-data', '/data-pattern', '/workflow-data/approve', '/permission', '/db-customize', '/product/product-workflows'].includes(config.url) && config.method !== 'get' && !['/', '/products'].includes(router.history.current.fullPath))
}

// error, config, code, request, response
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 5000
  // withCredentials: true // send cookies when cross-domain requests
});

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    // store.dispatch('commonApp/actionUpdateIsLoadingTable', false, { root: true });
    if (!listUrlDisableLoading?.includes(config.url)) {
      store.dispatch('commonApp/updateStatusAction', false, { root: true });
    }
    if (isLoadingFullscreen(config)) {
      store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
    }
    const accessToken = webStorage.getToken();
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    config.headers['Accept-Language'] = localStorage.getItem('language') ? localStorage.getItem('language') : 'ja'
    return config;
  },
  (error) => {
    store.dispatch('commonApp/updateStatusAction', false, { root: true });
    store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    Promise.reject(error);
  },
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);

      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    const res = response.data;
    store.dispatch('commonApp/updateStatusAction', true, { root: true });
    if (isLoadingFullscreen(response.config)) {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    }

    if (typeof res.code === 'Number' && res.code >= 400) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return response.data;
    }
  },
  (error) => {
    const res = error.response;
    store.dispatch('commonApp/updateStatusAction', false, { root: true });
    store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    if (!res) {
      return Promise.reject(error);
    }

    let throwable = {
      message: res.data.message,
      status: 0,
      code: res.data.code,
    };
    if (res.status === 401) {
      // TODO: handle logout
      //throwable.message = 'Unauthorization!';
      webStorage.removeAll();
      localStorage.removeItem('chunkAuthObj')
      router.push({ path: '/auth/login', query: { primary_uuid: store.state?.supplier?.primaryDataForm?.primary_uuid } }).catch(() => { });
    } else if (res.status === 422) {
      throwable.message = res.data.message;
      throwable = res.data;
      if (router.history.current.name === 'RegisterData') {
        // show popup
        let arrError = [];
        Object.keys(res.data.errors).forEach((err) => {
          arrError.push(...res.data.errors[err]);
        });
        store.commit('registerData/updateError', true);
        store.commit('registerData/updateErrorMess', arrError);
      }
    } else if (res.status === 400 && res.data.code === 'errors.forbidden_permission') {
      store.commit('registerData/updateError', true);
      store.commit('registerData/updateErrorMess', [res.data.message]);
    } else if (res.status === 400 && res?.data?.type === 'logout') {
      webStorage.removeAll();
      localStorage.removeItem('chunkAuthObj')
      router.push({ path: '/auth/login', query: { primary_uuid: res?.data?.uuid } }).catch(() => { });
      return res.data
    } else if(res.status === 400 && res.data.code === 'not_access' && router.history.current.name === 'NewRegisterData') {
      router.push({ path: '/emissions' }).catch(() => {});
    }

    throwable.status = res.status;

    return Promise.reject(throwable);
  },
);

export default service;
export const getFileName = () => fileName;
