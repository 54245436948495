<template>
  <v-dialog
    v-model="getDialog"
    fullscreen
    ref="dialog"
    :persistent="true"
    content-class="modal-fullscreen width-100pc"
    transition="dialog-bottom-transition"
  >
    <div class="content-main">
      <div class="head-category-popup">
        <span class="log-confirm_text mr-auto">{{ $t(getTitleModal()) }}</span>
        <div class="list-emission-close" @click="getDialog = false">
          <img class="head-image-dialog-popup mr-0 log-confirm--btn close" src="@/assets/images/dialog/close.svg" />
          <img
            class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
            src="@/assets/images/dialog/close_active.svg"
          />
          <div class="list-emission-close__text">{{ $t('b_export_report.button_close') }}</div>
        </div>
      </div>

      <div class="form-main">
        <!--  Form create -->
        <FormDataEvidence
          typeForm="create"
          v-show="typeForm === 'create'"
          :formData="formData"
          :descriptionScope="descriptionScope"
          :statusDialog="getDialog"
          :isShowDetail="isShowDetail"
          :isProcessProduct="isProcessProduct"
        >
          <template v-slot:actionPc>
            <common-button
              class="submit-register-btn"
              :label="$t('register_reduction.button_return')"
              v-ripple="false"
              @action="getDialog = false"
            />
            <common-button
              class="submit-register-btn"
              :label="$t('register_data.button_save_new')"
              type="colored"
              v-ripple="false"
              :disabled="validateForm()"
              @action="submit"
            />
          </template>

          <template v-slot:actionMobile>
            <common-button
              class="submit-register-btn"
              :label="$t('register_reduction.button_return')"
              v-ripple="false"
              @action="getDialog = false"
            />
            <common-button
              class="submit-register-btn"
              :label="$t('register_data.button_save_new')"
              type="colored"
              v-ripple="false"
              :disabled="validateForm()"
              @action="submit"
            />
          </template>
        </FormDataEvidence>

        <!--  Form view - update -->
        <FormDataEvidence
          v-if="typeForm === 'view'"
          :typeForm="formActionType"
          :formData="formData"
          :descriptionScope="descriptionScope"
          :statusDialog="getDialog"
          :isShowDetail="isShowDetail"
          @onRemoveImage="onRemoveImage()"
          :isProcessProduct="isProcessProduct"
        >
          <template v-slot:imagePreview>
            <div class="mt-2">
              <div v-if="previewType === 'image'" class="csv-input">
                <img id="image" style="width: 100%; height: 100%; object-fit: contain" />
              </div>
              <div v-if="previewType === 'pdf'" id="pdf" style="width: 100%; height: 252px"></div>
              <div class="csv-input no-preview-availbale" v-if="previewType === 'anothers'">{{ $t("evidence_storage.label_no_preview_availbale") }}</div>
            </div>

            <div v-if="typeForm === 'view' && !isUpateForm" class="description-download-file" @click="downloadFile">
              {{ $t('evidence_storage.label_download_file') }}
              <span class="underline"></span>
            </div>
          </template>

          <template v-if="typeForm === 'view' && !isUpateForm" v-slot:historyUpdate>
            <div v-if="evidences.data">
              <div v-if="isArlanguage">
                <div class="history-item mb-2 date-ar">
                  <!-- <div>{{ getTime }}</div> -->
                  <div class="date-format" dir="rtl">
                    <span class="history-item">{{ getDateCreateAt }}</span>
                  </div>
                  <div class="history-item">{{ evidences.data.user_created.name }}</div>
                  <div class="history-item__author">{{ $t('evidence_storage.label_action_created_at') }}</div>
                </div>
                <div class="history-item mb-2 date-ar">
                  <div class="date-format" dir="rtl">
                    <span class="history-item">{{ getDateUpdateAt }}</span>
                  </div>
                  <div class="history-item">{{ evidences.data.user_updated.name }}</div>
                  <div class="history-item__author">{{ $t('evidence_storage.label_action_updated_at') }}</div>
                </div>
              </div>
              <div v-else>
                <p class="history-item mb-2">
                  <span class="history-item__author">{{ $t('evidence_storage.label_action_created_at') }}</span>
                  {{ getDateCreateAt }}
                </p>
                <p class="history-item">
                  <span class="history-item__author">{{ $t('evidence_storage.label_action_updated_at') }}</span>
                  {{ getDateUpdateAt }}
                </p>
              </div>
            </div>
          </template>

          <template v-slot:actionPc>
            <template v-if="typeForm === 'view' && !isUpateForm">
              <common-button
                class="submit-register-btn"
                :label="$t('register_user_group.button_edit')"
                type="colored"
                v-ripple="false"
                :disabled="!!descriptionScope.onlyView"
                @action="onOpenEdit()"
              />
              <common-button
                class="submit-register-btn"
                :label="$t('register_data.button_delete')"
                type="redColor"
                v-ripple="false"
                :disabled="!!descriptionScope.onlyView"
                @action="dialogConfirm = true"
              />
            </template>
            <template v-else>
              <common-button
                class="submit-register-btn"
                :label="$t('register_reduction.button_return')"
                v-ripple="false"
                @action="cancelEdit()"
              />
              <common-button
                class="submit-register-btn"
                :label="$t('viewer_management.button_save')"
                type="colored"
                v-ripple="false"
                :disabled="validateFormUpate()"
                @action="submit"
              />
            </template>
          </template>

          <template v-slot:actionMobile>
            <template v-if="typeForm === 'view' && !isUpateForm">
              <common-button
                class="submit-register-btn"
                :label="$t('register_user_group.button_edit')"
                type="colored"
                v-ripple="false"
                :disabled="!!descriptionScope.onlyView"
                @action="onOpenEdit()"
              />
              <common-button
                class="submit-register-btn"
                :label="$t('register_data.button_delete')"
                type="redColor"
                v-ripple="false"
                :disabled="!!descriptionScope.onlyView"
                @action="dialogConfirm = true"
              />
            </template>

            <template v-else>
              <common-button
                class="submit-register-btn"
                :label="$t('register_reduction.button_return')"
                v-ripple="false"
                @action="cancelEdit()"
              />
              <common-button
                class="submit-register-btn"
                :label="$t('register_data.button_keep')"
                type="colored"
                v-ripple="false"
                :disabled="validateFormUpate()"
                @action="submit"
              />
            </template>
          </template>
        </FormDataEvidence>
      </div>
    </div>

    <notification-popup
      :dialog="dialogNotification"
      :message="messageNotify"
      @submit="(getDialog = false), (dialogNotification = false)"
    />

    <QuestionPopup
      :dialog="dialogConfirm"
      :confirm="true"
      :message="$t('evidence_storage.label_confirm_delete')"
      :confirmText="$t('popup.button_delete')"
      @close="dialogConfirm = false"
      @submit="deleteEvidence()"
      classes="register-question"
    ></QuestionPopup>

    <!-- <ErrorPopup :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false" /> -->
  </v-dialog>
</template>
<script>
import FormDataEvidence from '@/views/emissions/EvidenceStorage/components/FormData.vue';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import { addThoundSandComma } from '@/utils/convertNumber';
import { formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import moment from 'moment';
import PDFObject from 'pdfobject';

import { create, getDetailFile } from '@/api/evidence-storage';

export default {
  components: { FormDataEvidence, CommonButton, NotificationPopup, QuestionPopup },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    typeForm: {
      type: String,
      default: '',
    },
    evidences: {
      type: Object,
    },
    descriptionScope: {
      type: Object,
      default() {
        return { data: '', note: '', value: '' };
      },
    },
    isShowDetail: {
      type: Boolean,
      default: true,
    },
    isProcessProduct: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formData: {
        data: '',
      },
      isUpateForm: false,
      isLoading: false,
      dialogNotification: false,
      formActionType: 'view',
      dialogConfirm: false,
      messageNotify: '',
      fileName: '',
      filePackUp: '',
      isInputUpdate: false,
      isFileUpdate: false,
      previewType: 'image',
    };
  },

  watch: {
    dialog(visible) {
      if (visible) {
        if (this.typeForm === 'view') {
          this.getDetailFile();
        }
        this.formData = {
          note: this.evidences.data?.note,
          value: this.evidences.value,
        };
      } else {
        this.formActionType = 'view';
        this.isUpateForm = false;

        const elImage = document.getElementById('image');
        if (elImage) elImage.style.display = 'none';

        const elements = document.getElementById('pdfFile');
        if (elements) elements.remove();
      }
    },
  },

  computed: {
    getDialog: {
      get() {
        let htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.style.overflow = this.dialog ? 'hidden' : 'auto';

        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    getDateCreateAt() {
      const createdAt = this.evidences.data.created_at;
      const year = moment(createdAt).year();
      const month = moment(createdAt).month() + 1;
      const date = moment(createdAt).date();
      const time = this.isArlanguage ? moment(createdAt).format('ss:mm:HH') : moment(createdAt).format('HH:mm:ss');
      return this.$t('evidence_storage.label_action_time', {
        user: this.evidences.data.user_created.name,
        year: year,
        month: month,
        date: date,
        time: time,
      });
    },
    getDateUpdateAt() {
      const updatedAt = this.evidences.data.updated_at;
      const year = moment(updatedAt).year();
      const month = moment(updatedAt).month() + 1;
      const date = moment(updatedAt).date();
      const time = this.isArlanguage ? moment(updatedAt).format('ss:mm:HH') : moment(updatedAt).format('HH:mm:ss');
      return this.$t('evidence_storage.label_action_time', {
        user: this.evidences.data.user_updated.name,
        year: year,
        month: month,
        date: date,
        time: time,
      });
    },
    
    isArlanguage() {
      return this.$i18n.locale === 'ar-AE';
    },
  },

  methods: {
    validateForm() {
      return this.formData.data === '' || this.formData.data === undefined || this.isLoading;
    },
    validateFormUpate() {
      const isNoteUpdated = this.formData.note !== this.evidences.data?.note;
      const isValueUpdated =
        addThoundSandComma(formatValue(this.formData.value)?.substring(0, 20)) !==
        addThoundSandComma(formatValue(this.evidences.value)?.substring(0, 20));

      this.isInputUpdate = isNoteUpdated || isValueUpdated;
      this.isFileUpdate = (this.formData.data != '' && this.formData.data != undefined) || this.fileName != '';

      return !this.isFileUpdate;
    },

    getTitleModal() {
      return this.typeForm != 'create'
        ? 'evidence_storage.title_detail_evidence_storage'
        : 'evidence_storage.title_create_evidence_storage';
    },

    onRemoveImage() {
      this.fileName = '';
      this.formData.data = '';
      const elements = document.getElementById('pdfFile');
      if (elements) elements.remove();
    },

    cancelEdit() {
      this.formActionType = 'view';
      this.isUpateForm = false;

      this.formData = {
        note: this.evidences.data?.note,
        value: this.evidences.value,
      };
      if (!this.fileName) {
        this.$nextTick(() => {
          this.fileName = this.filePackUp;
          if (this.previewType = 'image') {
            document.getElementById('image').src = this.filePackUp;
          } else if (this.previewType = 'pdf') {
            PDFObject.embed(this.filePackUp, '#pdf', { id: 'pdfFile' });
          }
        });
      }
    },

    submit() {
      let payload = {
          note: this.formData.note,
          data: this.formData.data,
          ...(this.isShowDetail && {
            pattern_id: this.descriptionScope.pattern_id,
            category_id: this.descriptionScope.category,
            scope_id: this.descriptionScope.scope,
          }),
        };
      if(this.isProcessProduct) {
        payload = {
          is_process_emission: 1,
          data: this.formData.data,
          note: this.formData.note,
        }
      }
      if (this.isLoading) return;

      if (!this.formData.data) {
        if (this.isInputUpdate) {
          const options = {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
          };

          const tokyoDate = new Date().toLocaleString('en-US', options);
          const formattedTokyoDate = moment(tokyoDate).format('YYYY-MM-DD HH:mm:ss');
          this.$emit('onSubmitSuccess', {
            note: this.formData.note,
            value: this.formData.value,
            updated_at: formattedTokyoDate,
          });
        }
        this.openDialogSucess();
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      let form_data = new FormData();
      Object.keys(payload).forEach((key) => form_data.append(key, payload[key] ? payload[key] : ''));

      create(form_data)
        .then((res) => {
          if (res.success) {
            this.openDialogSucess();
            this.$emit('onSubmitSuccess', {
              ...res.record_insert,
              note: this.formData.note,
              value: this.formData.value,
            });
          }
        })
        .catch((err) => {
          this.dialogLimit = true;
          this.messageLimit = this.$t('list_menu.description_message_notification');
        })
        .finally((_) => {
          this.isLoading = false;
        });
    },

    openDialogSucess() {
      this.dialogNotification = true;
      if (this.typeForm === 'create') {
        this.messageNotify = this.$t('evidence_storage.label_save_success');
      } else {
        this.messageNotify = this.$t('evidence_storage.label_update_success');
      }
    },

    deleteEvidence() {
      this.$emit('onDelete');
      this.getDialog = false;
      this.dialogConfirm = false;
    },

    onOpenEdit() {
      this.isUpateForm = true;
      this.formActionType = 'update';
    },

    downloadFile() {
      const link = document.createElement('a');
      link.href = this.fileName;
      link.setAttribute('download', this.evidences?.data?.file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getDetailFile() {
      const params = {
        evidence: {
          pattern_id: this.evidences.data.pattern_id,
          unix_time: this.evidences.data?.unix_time,
          file_name: this.evidences.data?.file_name,
          created_by: this.evidences.data?.created_by,
        },
      };
      if (this.isProcessProduct) {
        delete params.evidence.pattern_id;
        params.evidence.is_process_emission = 1;
      }
      const res = await getDetailFile(params);
      if (res.type == 'application/pdf') {
        this.previewType = 'pdf';
        const filePDF = URL.createObjectURL(res);
        this.fileName = filePDF;
        this.filePackUp = filePDF;
        this.$nextTick(() => {
          PDFObject.embed(filePDF, '#pdf', { id: 'pdfFile' });
        });
      } else if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].includes(res.type)) {
        this.previewType = 'image';
        const image = URL.createObjectURL(res);
        this.fileName = image;
        this.filePackUp = image;
        this.$nextTick(() => {
          const el = document.getElementById('image');
          el.style.display = 'block';
          el.src = image;
        });
      } else {
        this.previewType = 'anothers';
        const content = URL.createObjectURL(
          new Blob([res], { type: res.type })
        );
        this.fileName = content;
        this.filePackUp = content;
      }
    },
  },
};
</script>



<style lang="scss">
.modal-fullscreen {
  background: $bgExtraLight;
}

.modal-open {
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;

  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }

  .content-main {
    height: 100%;

    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }

    .description-download-file {
      color: $goldMid;
      cursor: pointer;

      font-size: 15px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.75px;
      text-decoration-line: underline;
      position: relative;
      width: max-content;
      text-align: left;

      .underline {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $goldMid;
        transition: width 0.3s ease;
        bottom: 2px;
      }

      &.disable {
        cursor: text;
        color: $monoBlack;
      }

      &:hover {
        text-decoration: underline;

        .underline {
          width: 100%;
        }
      }
    }

    .form-main {
      margin-top: 94px;
    }

    .head-category-popup {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      background: #eff5f5;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      border-bottom: solid 1px rgba(121, 134, 134, 0.12);

      .log-confirm_text {
        height: 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }

      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #f7f7f2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;

        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }

        .close-active {
          display: none;
        }

        .close {
          display: block;
        }

        &:hover {
          background: $goldLight;

          .close-active {
            display: block;
          }

          .close {
            display: none;
          }

          .list-emission-close__text {
            color: $monoWhite;
          }
        }

        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;

          .close-active {
            display: none;
          }

          .close {
            display: block;
          }

          .list-emission-close__text {
            color: $monoBlack;
          }
        }
      }
    }

    .head-emission-category-border {
      width: 100%;
      height: 1px;
      background: rgba(121, 134, 134, 0.12);
    }
  }

  @media (max-width: 768px) {
    .head-category-popup {
      padding: 16px 20px !important;
    }

    .form-main {
      margin-top: 134px !important;
    }
  }

  .history-item {
    color: $monoDark;
    /* P/ExtraSmall/Regular */
    // font-family: 'Source Han Sans JP';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.6px;

    &__author {
      color: $goldMid;
    }
  }

  .date-ar {
    display: flex;
    gap: 5px;
    align-items: baseline;
    &-format {
      text-align: left;
    }
  }
}
</style>
