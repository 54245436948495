import api from '../api/product';

const defaultState = () => ({
  dashboard: {
    emissionsByProducts: {
      isGetAllProduct: true,
      productMasterIds: [],
      selectedType: 0,
      selectedData: {
        selectedYear: null,
        month: null,
        year: null,
        indexSelected: null,
        pageCurrent: null
      }
    },
    emissionsPerProducts: {
      isGetAllProduct: true,
      productMasterIds: [],
      selectedType: 0,
      selectedData: {
        duration_id: null,
        duration_type_month_id: null,
        month: null,
        year: null,
      }
    },
    emissionsHighProducts: {
      selectedType: 0,
      highEmissionsForAllProducts: true,
      selectedData: {
        duration_id: null,
        duration_type_month_id: null,
        month: null,
        year: null,
        numberItems: 5,
      },
    },
  },
  colors: [],
  notification: {},
  isLoadingSubmitting: false,
  apiResponseObject: {},
  previousRoute: {},
});

export const state = defaultState();
const getters = {
  isLoadingSubmitting: state => state.isLoadingSubmitting,
  apiResponseObject: state => state.apiResponseObject
};
export const mutations = {
  resetState(state) {
    Object.assign({}, defaultState())
    state.dashboard.emissionsByProducts.productMasterIds = []
    setStateToNull(state)
  },
  updateEmissionsByProducts(state, newData) {
    state.dashboard.emissionsByProducts = newData;
  },
  updateEmissionsPerProducts(state, newData) {
    state.dashboard.emissionsPerProducts = newData;
  },
  updateEmissionsHighProducts(state, newData) {
    state.dashboard.emissionsHighProducts = newData;
  },
  updateColorProducts(state, newData) {
    const defaultColors = [
      'rgba(0, 143, 204, 1)',
      'rgba(228, 91, 88, 1)',
      'rgba(128, 188, 98, 1)',
      'rgba(224, 163, 41, 1)',
      'rgba(159, 99, 189, 1)',
      'rgba(172, 174, 78, 1)',
    ]
    state.colors = [...defaultColors, ...newData];
  },
  updateNotification(state, newData) {
    state.notification = newData
  },
  setLoading(state, isLoading) {
    state.isLoadingSubmitting = isLoading;
  },
  setApiResponse(state, response) {
    state.apiResponseObject = response;
  },
  setPreviousRoute(state, newData) {
    state.previousRoute = newData;
  }
};

export const actions = {
  resetState({ commit }) {
    commit('resetState');
  },
  updateEmissionsByProducts({ commit }, data) {
    commit('updateEmissionsByProducts', data);
  },
  updateEmissionsPerProducts({ commit }, data) {
    commit('updateEmissionsPerProducts', data);
  },
  updateEmissionsHighProducts({ commit }, data) {
    commit('updateEmissionsHighProducts', data);
  },
  actionUpdateColorProducts({ commit }, data) {
    commit('updateColorProducts', data);
  },
  actionUpdateNotification({ commit }, data) {
    commit('updateNotification', data);
  },
  async addProductDataWorkFollow({ commit }, payload) {
    try {
      commit("setLoading", true);
      commit("setApiResponse", await api.addDataProductWorkWorkFollowApi(payload));
      commit("setLoading", false);
    } catch (error) {
      commit("setLoading", false);
      commit("setApiResponse", error);
    }
  },
  async updateproductDataWorkFollow({ commit }, payload) {
    try {
      commit("setLoading", true);
      commit("setApiResponse", await api.updateDataProductWorkFollowApi(payload));
      commit("setLoading", false);
    } catch (error) {
      commit("setLoading", false);
      commit("setApiResponse", error);
    }
  },
  actionUpdatePreviousRoute({commit}, data) {
    commit('setPreviousRoute', data);
  }
};

const setStateToNull = (state) => {
  state.dashboard = {
    emissionsByProducts: {
      isGetAllProduct: true,
      productMasterIds: [],
      selectedType: 0,
      selectedData: {
        duration_id: null,
        month: null,
        year: null,
        indexSelected: null
      }
    },
    emissionsPerProducts: {
      isGetAllProduct: true,
      productMasterIds: [],
      selectedType: 0,
      selectedData: {
        duration_id: null,
        duration_type_month_id: null,
        month: null,
        year: null,
        yearTypeYear: null,
        yearTypeMonth: null,
      }
    },
    emissionsHighProducts: {
      selectedType: 0,
      highEmissionsForAllProducts: true,
      selectedData: {
        duration_id: null,
        duration_type_month_id: null,
        month: null,
        year: null,
        yearTypeYear: null,
        yearTypeMonth: null,
        numberItems: 5,
      },
    },
  };
  state.colors = [];
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
