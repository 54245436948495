import { render, staticRenderFns } from "./type-selection.vue?vue&type=template&id=d6edb838&scoped=true&"
import script from "./type-selection.vue?vue&type=script&lang=js&"
export * from "./type-selection.vue?vue&type=script&lang=js&"
import style0 from "./type-selection.vue?vue&type=style&index=0&id=d6edb838&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6edb838",
  null
  
)

export default component.exports