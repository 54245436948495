import i18n from '@/lang/i18n';

export const maximum128 = [
  'company_name',
  'energy_type',
  'wsu_source',
  'supplier_name',
  'service_name',
  'processing_method',
  // 'industrial_waste_item',
  'wsu_type',
  'wsu_source',
  // 'transportation_facilities',
  'lease_asset_name',
  'activity_name',
  'activity_source',
  'intermediate_product',
  'ides_name_company',
  'activity',
  'identification_number',
  'kinds',
  'db_customize_id',
  // 'ir_calculation_unit',
  'dir_date',
  'db_customize_id_1',
  'db_id',
  'unit_source1',
  'unit_source2',
];

export const maximum20 = [
  'organizational_division',
  'wsu_value',
  'wsu_unit',
  'qd_unit',
  'cpa_unit',
  'energy_unit',
  'item_unit',
  'wsu_unit',
  'travel_unit',
  'te_unit',
  'iss_unit',
  'iss_value',
  'energy_unit',
  'sales_volume_unit',
  'scale_index_unit',
  'ie_company_unit',
  'le_unit',
  'ir_owned',
  'dir_value',
  'dir_unit',
  'ir_ratio',
  'ir_investment_on_total',
  'wsu_unit_activity',
  'unit',
  'value_source1',
  'value_source2',
];

export const maximum10 = [
  // 'fuel_efficiency', // s1c1m3
];

export const maximum10WithoutRequired = [
  'loading_data_rate',
  'fuel_efficiency'
];


export const maximum20WithoutRequired = [
  'value',
  'loading_data_maximum',
  'distance',
  'qd_value',
  'cpa_value',
  'energy_value',
  'distance_value',
  'energy_saving_value',
  'scope12_overlap',
  'transport_weight_value',
  'sea_distance_value',
  'industrial_waste_value',
  'travel_value',
  'te_value',
  'employees_number_value',
  'employees_number_unit',
  'working_days_number_value',
  'working_days_number_unit',
  'sales_volume_value',
  'scale_index_value',
  'ie_company_value',
  'ie_year',
  'le_value',
  'pj_investment_ratio',
  'ir_total_investment',
  'ir_internal_investment',
  'ir_calculation_numerator',
  'ir_calculated_denominator',
  'ir_company_hold',
  'ir_release_quantity'
];

export const maximum128WithoutRequired = [
  'business_name',
  'target_model',
  'qd_source',
  'business_content',
  'cpa_source',
  'energy_source',
  'product_name',
  'destination_country_export',
  'transport_weight_source',
  'sea_distance_source',
  'item_source',
  'travel_source',
  'te_source',
  'source',
  'sales_volume_source',
  'procurement',
  'scale_index_source',
  'ides_industry',
  'ie_scope',
  'investment_destination',
  'activity',
  'industrial_waste_item'
];

export const listNumbers = [
  'industrial_waste_value',
  'te_value',
  'employees_number_value',
  'working_days_number_value',
  'loading_data_maximum',
  'loading_data_rate',
  'distance',
  'fuel_efficiency',
  'energy_value',
  'sales_volume_value',
  'scale_index_value',
  // 'dir_value',
  'qd_value',
  'energy_saving_value',
  'scope12_overlap',
  'aoa_value',
  'value',
  'cpa_value',
  'transport_weight_value',
  'sea_distance_value',
  'distance_value',
  // 'value_source1',
  // 'value_source2',
  'ir_company_hold',
  'ir_release_quantity',
  'ie_company_value',
  'pj_investment_ratio',
  'le_value',
  'travel_value',
  'ir_total_investment',
  'ir_internal_investment',
  'ir_calculation_numerator',
  'ir_calculated_denominator',
  'emissions'
  // 'wsu_value'
];

export const BLANK_ID = 'Blank';

export const MESSAGE_NOT_NULL = i18n.t('validation.error_required_field');
export const MESSAGE_MAX_10_CHARACTOR = i18n.t('validation.error_maximum_10_charactors');
export const MESSAGE_MAX_20_CHARACTOR = i18n.t('validation.error_maximum_20_charactors');
export const MESSAGE_MAX_128_CHARACTOR = i18n.t('validation.error_maximum_128_charactors');
export const MESSAGE_IS_NUMBER = i18n.t('validation.error_number_field');
export const MESSAGE_POSITIVE_NUMBER = i18n.t('validation.error_positive_value');
export const MESSAGE_INCORRECT_DATE = i18n.t('validation.error_date_format');
export const MESSAGE_INCORRECT_YEAR = i18n.t('validation.error_year_format');

export const ORGANIZATIONAL_DIVISION = [
  i18n.t('b_list_all.pulldown_single_company'),
  i18n.t('b_list_all.pulldown_subsidiaries_company'),
  i18n.t('b_list_all.pulldown_associated_company'),
  i18n.t('b_list_all.pulldown_associated_company_other')
]

export const FILTER_DATA = {
  ORGANIZATIONAL: 'organizational_division',
  COMPANY: 'company_name',
  BUSINESS: 'business_name',
  COUNTRY: 'country',
  LAYER3: 'layer_3',
  LAYER4: 'layer_4',
  LAYER5: 'layer_5',
  LAYER6: 'layer_6',
}

// export const ORGANIZATIONAL_DATA = {
//   1: '単体',
//   2: '子会社',
//   3: '関連会社',
//   4: 'その他関連会社',
// }
export const ORGANIZATIONAL_DATA = {
  1: i18n.t('b_list_all.pulldown_single_company'),
  2: i18n.t('b_list_all.pulldown_subsidiaries_company'),
  3: i18n.t('b_list_all.pulldown_associated_company'),
  4: i18n.t('b_list_all.pulldown_associated_company_other'),
}

export const MESSAGE_NOT_COMPARE_DATA = '入力された組織区分/法人名/事業名の組み合わせが存在しません。'


export const energyTypes = [
  {
    key: '自動車輸送 ガソリンの使用',
    value: '自動車輸送 ガソリンの使用'
  },
  {
    key: '自動車輸送 軽油の使用',
    value: '自動車輸送 軽油の使用'
  }
]

export const sources = [
  {
    type: 'gasoline',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '環境省DB2021[2]輸送 燃費法 「ガソリン」',
    value_source: '2.32166'
  },
  {
    type: 'oil',
    unit: 'kl',
    unit_source: 'tCO2/kl',
    source: '環境省DB2021[2]輸送 燃費法 「軽油」',
    value_source: '2.58496333333333'
  },
]

export const APPROVAL_TYPE = {
  APPROVE: 1,
  REJECT: 2,
}

export const APPROVAL_STATUS = {
  APPROVER_RETURN: 2,
  RETURN: 3,
  APPROVER_APPROVAL: 4,
  APPROVAL: 5,
}

export const ACTION_HEADER_TABLE = {
  SAVE_TEMPRORA: 'save-temprora',
  SAVE_TEMPLATE: 'save-template',
  DELETE_DATA: 'delete-data',
  SUBMIT_DATA: 'submit',
  OVERRIDE_TEMPLATE: 'override template',
  CANCEL_SUBMITTED: 'cancelSubmit',
  CLONE_TEMPLATE: 'clone template',
  REVIEWING_DATA: 'reviewing data',
  ACCEPT_CANCEL_SUBMITTED: 'approveCancel',
  UPDATE_APPROVED_DATA: 'update approved data',
  UPDATE: 'update',
  UPDATE_TEMPLATE: 'update',
  SAVE_DRAFT: 'saveDraft',
  SAVE_TEMPLATE_PRODUCT: 'saveTemplate',
  APPROVED_DATA: 'approve',
};

export const SCOPE = {
  SCOPE_1: 1,
  SCOPE_2: 2,
  SCOPE_3: 3,
  SCOPE_4: 4,
};

export const CATEGORY = {
  CATEGORY_1: 1,
  CATEGORY_2: 2,
  CATEGORY_3: 3,
  CATEGORY_4: 4,
  CATEGORY_5: 5,
  CATEGORY_6: 6,
  CATEGORY_7: 7,
  CATEGORY_15: 15,
  CATEGORY_16: 16,
}

export const SCOPE_1 = 1;
export const SCOPE_2 = 2;
export const SCOPE_3 = 3;
export const SCOPE_4 = 3;
export const CATEGORY_1 = 1;
export const CATEGORY_2 = 2;
export const CATEGORY_3 = 3;
export const CATEGORY_4 = 4;
export const CATEGORY_5 = 5;
export const CATEGORY_6 = 6;
export const CATEGORY_7 = 7;
export const CATEGORY_15 = 15;
export const CATEGORY_16 = 16;
export const METHOD_3 = 3;

export const CANCEL_TYPE = {
  ACCEPT: 1,
  REJECT: 0
}

export const REGISTER_PULLDOWN = {
  CREATE_NEW: i18n.t('b_list_product.button_input_manually'),
  CREATE_FROM_TEMPLATE: i18n.t('b_list_product.button_register_from_the_template'),
  CREATE_FROM_CSV: 'CSVインポート',
}

export const LIST_PULLDOWN = [
  {
    text: i18n.t('b_list_product.button_input_manually'),
    icon: 'csv-input/manually.svg',
    id: 'register-pulldown',
  },
  {
    text: i18n.t('b_list_product.button_register_from_the_template'),
    icon: 'csv-input/template.svg',
    id: 'register-by-template-pulldown',
  },
  {
    text: i18n.t('b_list_product.button_register_from_the_csv'),
    icon: 'csv-input/csv-import.svg',
    id: 'register-by-import-pulldown',
  }
]

export const itemKeyHasFormatNumber = [
  'value', 'loading_data_maximum', 'loading_data_rate',
  'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
  'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
  'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
  'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
  'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
  'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
  'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value', 'wsu_value', 'emissions', 'il_amount', 'il_total_amount', 'ie_ghg_emissions',
  'dq_quality_score', 'revenue', 'purchase_level', 'government_bond_emissions', 'gdp_ppp_adjusted',
  'loan_amount', 'amount', 'total_capital', 'value_number_1', 'value_number_2', 'value_purchase', 'emissions_factor', 'value_number', 'amount_activity'
];

export const GICS_PULLDOWN = [
  {
    key: "石油・ガス掘削",
    value: i18n.t("gics_pulldown.oil_gas_drilling"),
    autoField: "0.00",
  },
  {
    key: "石油・ガス装置・サービス",
    value: i18n.t("gics_pulldown.oil_gas_equipment_services"),
    autoField: "0.00",
  },
  {
    key: "総合石油・ガス",
    value: i18n.t("gics_pulldown.integrated_oil_gas"),
    autoField: "0.00",
  },
  {
    key: "石油・ガス探査・開発",
    value: i18n.t("gics_pulldown.oil_gas_exploration_production"),
    autoField: "0.00",
  },
  {
    key: "石油・ガス精製・販売",
    value: i18n.t("gics_pulldown.oil_gas_refining_marketing"),
    autoField: "0.00",
  },
  {
    key: "石油・ガス貯蔵・輸送",
    value: i18n.t("gics_pulldown.oil_gas_storage_transportation"),
    autoField: "11,129,263.0399851",
  },
  {
    key: "石炭・消耗燃料",
    value: i18n.t("gics_pulldown.coal_consumable_fuels"),
    autoField: "0.00",
  },
  {
    key: "基礎化学品",
    value: i18n.t("gics_pulldown.commodity_chemicals"),
    autoField: "0.00",
  },
  {
    key: "総合化学",
    value: i18n.t("gics_pulldown.diversified_chemicals"),
    autoField: "0.00",
  },
  {
    key: "肥料・農薬",
    value: i18n.t("gics_pulldown.fertilizers_agricultural_chemicals"),
    autoField: "0.00",
  },
  {
    key: "工業用ガス",
    value: i18n.t("gics_pulldown.industrial_gases"),
    autoField: "0.00",
  },
  {
    key: "特殊化学品",
    value: i18n.t("gics_pulldown.specialty_chemicals"),
    autoField: "0.00",
  },
  {
    key: "建設資材",
    value: i18n.t("gics_pulldown.construction_materials"),
    autoField: "0.00",
  },
  {
    key: "金属・ガラス・プラスチック容器",
    value: i18n.t("gics_pulldown.metal_glass_plastic_containers"),
    autoField: "0.00",
  },
  {
    key: "紙・プラスチック包装製品・材料",
    value: i18n.t("gics_pulldown.paper_plastic_packaging_products_materials"),
    autoField: "0.00",
  },
  {
    key: "アルミ",
    value: i18n.t("gics_pulldown.aluminum"),
    autoField: "0.00",
  },
  {
    key: "各種金属・鉱業",
    value: i18n.t("gics_pulldown.diversified_metals_mining"),
    autoField: "0.00",
  },
  {
    key: "銅",
    value: i18n.t("gics_pulldown.copper"),
    autoField: "0.00",
  },
  {
    key: "金",
    value: i18n.t("gics_pulldown.gold"),
    autoField: "0.00",
  },
  {
    key: "貴金属・鉱物",
    value: i18n.t("gics_pulldown.precious_metals_minerals"),
    autoField: "0.00",
  },
  {
    key: "銀",
    value: i18n.t("gics_pulldown.silver"),
    autoField: "0.00",
  },
  {
    key: "鉄鋼",
    value: i18n.t("gics_pulldown.steel"),
    autoField: "0.00",
  },
  {
    key: "林産品",
    value: i18n.t("gics_pulldown.forest_products"),
    autoField: "2,037,778,263.18272",
  },
  {
    key: "紙製品",
    value: i18n.t("gics_pulldown.paper_products"),
    autoField: "0.00",
  },
  {
    key: "航空宇宙・防衛",
    value: i18n.t("gics_pulldown.aerospace_defense"),
    autoField: "22,565,783,378.3872",
  },
  {
    key: "建設関連製品",
    value: i18n.t("gics_pulldown.building_products"),
    autoField: "0.00",
  },
  {
    key: "建設・土木",
    value: i18n.t("gics_pulldown.construction_engineering"),
    autoField: "0.00",
  },
  {
    key: "電気部品・設備",
    value: i18n.t("gics_pulldown.electrical_components_equipment"),
    autoField: "16,467,834,734.9493",
  },
  {
    key: "重電機設備",
    value: i18n.t("gics_pulldown.heavy_electrical_equipment"),
    autoField: "32,969,812,808.864",
  },
  {
    key: "コングロマリット",
    value: i18n.t("gics_pulldown.industrial_conglomerates"),
    autoField: "0.00",
  },
  {
    key: "建設機械・大型輸送設備",
    value: i18n.t("gics_pulldown.construction_machinery_heavy_transportation_equipment"),
    autoField: "0.00",
  },
  {
    key: "農業機械",
    value: i18n.t("gics_pulldown.agricultural_farm_machinery"),
    autoField: "0.00",
  },
  {
    key: "産業機械・用品・部品",
    value: i18n.t("gics_pulldown.industrial_machinery_supplies_components"),
    autoField: "0.00",
  },
  {
    key: "商社・流通業",
    value: i18n.t("gics_pulldown.trading_companies_distributors"),
    autoField: "37,054,654,313.699",
  },
  {
    key: "商業印刷",
    value: i18n.t("gics_pulldown.commercial_printing"),
    autoField: "27,551,685,160.6447",
  },
  {
    key: "環境関連・ファシリティサービス",
    value: i18n.t("gics_pulldown.environmental_facilities_services"),
    autoField: "0.00",
  },
  {
    key: "事務サービス・用品",
    value: i18n.t("gics_pulldown.office_services_supplies"),
    autoField: "13,430,452,207.2979",
  },
  {
    key: "各種支援サービス",
    value: i18n.t("gics_pulldown.diversified_support_services"),
    autoField: "130,568,506,167.782",
  },
  {
    key: "セキュリティ・警報装置サービス",
    value: i18n.t("gics_pulldown.security_alarm_services"),
    autoField: "0.00",
  },
  {
    key: "人事・雇用サービス",
    value: i18n.t("gics_pulldown.human_resource_employment_services"),
    autoField: "0.00",
  },
  {
    key: "調査・コンサルティングサービス",
    value: i18n.t("gics_pulldown.research_consulting_services"),
    autoField: "0.00",
  },
  {
    key: "情報処理・外注サービス",
    value: i18n.t("gics_pulldown.data_processing_outsourced_services"),
    autoField: "0.00",
  },
  {
    key: "航空貨物・物流サービス",
    value: i18n.t("gics_pulldown.air_freight_logistics"),
    autoField: "26,825,159,726.1089",
  },
  {
    key: "旅客航空輸送",
    value: i18n.t("gics_pulldown.passenger_airlines"),
    autoField: "26,825,159,726.1089",
  },
  {
    key: "海上運輸",
    value: i18n.t("gics_pulldown.marine_transportation"),
    autoField: "6,797,219,761.49932",
  },
  {
    key: "鉄道運輸",
    value: i18n.t("gics_pulldown.railroads"),
    autoField: "77,698,619,736.194",
  },
  {
    key: "貨物陸上輸送",
    value: i18n.t("gics_pulldown.cargo_ground_transportation"),
    autoField: "0.00",
  },
  {
    key: "旅客陸上輸送",
    value: i18n.t("gics_pulldown.passenger_ground_transportation"),
    autoField: "0.00",
  },
  {
    key: "空港サービス",
    value: i18n.t("gics_pulldown.airport_services"),
    autoField: "103,472,394,095.367",
  },
  {
    key: "高速道路・鉄道路線",
    value: i18n.t("gics_pulldown.highways_railtracks"),
    autoField: "103,472,394,095.367",
  },
  {
    key: "港湾サービス",
    value: i18n.t("gics_pulldown.marine_ports_services"),
    autoField: "6,797,219,761.49932",
  },
  {
    key: "自動車用部品・装置",
    value: i18n.t("gics_pulldown.auto_parts_equipment"),
    autoField: "3,269,657,283.19425",
  },
  {
    key: "タイヤ・ゴム",
    value: i18n.t("gics_pulldown.tires_rubber"),
    autoField: "3,269,657,283.19425",
  },
  {
    key: "自動車製造",
    value: i18n.t("gics_pulldown.automobile_manufacturers"),
    autoField: "86,108,480,826.4134",
  },
  {
    key: "自動二輪車製造",
    value: i18n.t("gics_pulldown.motorcycle_manufacturers"),
    autoField: "86,108,480,826.4134",
  },
  {
    key: "民生用電子機器",
    value: i18n.t("gics_pulldown.consumer_electronics"),
    autoField: "16,467,834,734.9493",
  },
  {
    key: "家具・装飾",
    value: i18n.t("gics_pulldown.home_furnishings"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "住宅建設",
    value: i18n.t("gics_pulldown.homebuilding"),
    autoField: "0.00",
  },
  {
    key: "家庭用電気機器",
    value: i18n.t("gics_pulldown.household_appliances"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "家庭用品・雑貨",
    value: i18n.t("gics_pulldown.housewares_specialties"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "レジャー用品",
    value: i18n.t("gics_pulldown.leisure_products"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "アパレル・アクセサリー・贅沢品",
    value: i18n.t("gics_pulldown.apparel_accessories_luxury_goods"),
    autoField: "16,476,804,739.8182",
  },
  {
    key: "履物",
    value: i18n.t("gics_pulldown.footwear"),
    autoField: "3,845,392,798.90381",
  },
  {
    key: "繊維",
    value: i18n.t("gics_pulldown.textiles"),
    autoField: "0.00",
  },
  {
    key: "カジノ・ゲーム",
    value: i18n.t("gics_pulldown.casinos_gaming"),
    autoField: "0.00",
  },
  {
    key: "ホテル・リゾート・クルーズ船",
    value: i18n.t("gics_pulldown.hotels_resorts_cruise_lines"),
    autoField: "377,591,351,496.457",
  },
  {
    key: "レジャー設備",
    value: i18n.t("gics_pulldown.leisure_facilities"),
    autoField: "114,323,606,311.808",
  },
  {
    key: "レストラン",
    value: i18n.t("gics_pulldown.restaurants"),
    autoField: "377,591,351,496.457",
  },
  {
    key: "教育サービス",
    value: i18n.t("gics_pulldown.education_services"),
    autoField: "0.00",
  },
  {
    key: "専門消費者サービス",
    value: i18n.t("gics_pulldown.specialized_consumer_services"),
    autoField: "72,245.6184557368",
  },
  {
    key: "販売",
    value: i18n.t("gics_pulldown.distributors"),
    autoField: "37,054,654,313.699",
  },
  {
    key: "大規模小売り",
    value: i18n.t("gics_pulldown.broadline_retail"),
    autoField: "16,476,804,739.8182",
  },
  {
    key: "衣料小売り",
    value: i18n.t("gics_pulldown.apparel_retail"),
    autoField: "16,476,804,739.8182",
  },
  {
    key: "コンピュータ・電子機器小売り",
    value: i18n.t("gics_pulldown.computer_electronics_retail"),
    autoField: "0.00",
  },
  {
    key: "住宅関連用品小売り",
    value: i18n.t("gics_pulldown.home_improvement_retail"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "その他の専門小売り",
    value: i18n.t("gics_pulldown.other_specialty_retail"),
    autoField: "0.00",
  },
  {
    key: "自動車小売り",
    value: i18n.t("gics_pulldown.automotive_retail"),
    autoField: "98,746,555,535.9987",
  },
  {
    key: "家具・装飾小売り",
    value: i18n.t("gics_pulldown.homefurnishing_retail"),
    autoField: "98,746,555,535.9987",
  },
  {
    key: "薬品小売り",
    value: i18n.t("gics_pulldown.drug_retail"),
    autoField: "0.00",
  },
  {
    key: "食品流通",
    value: i18n.t("gics_pulldown.food_distributors"),
    autoField: "0.00",
  },
  {
    key: "食品小売り",
    value: i18n.t("gics_pulldown.food_retail"),
    autoField: "0.00",
  },
  {
    key: "生活必需品小売り",
    value: i18n.t("gics_pulldown.consumer_staples_merchandise_retail"),
    autoField: "0.00",
  },
  {
    key: "醸造",
    value: i18n.t("gics_pulldown.brewers"),
    autoField: "67,069,657,113.8841",
  },
  {
    key: "蒸留酒・ワイン",
    value: i18n.t("gics_pulldown.distillers_vintners"),
    autoField: "67,069,657,113.8841",
  },
  {
    key: "清涼飲料・ノンアルコール飲料",
    value: i18n.t("gics_pulldown.soft_drinks_non-alcoholic_beverages"),
    autoField: "67,069,657,113.8841",
  },
  {
    key: "農産物・サービス",
    value: i18n.t("gics_pulldown.agricultural_products_services"),
    autoField: "0.00",
  },
  {
    key: "包装食品・肉",
    value: i18n.t("gics_pulldown.packaged_foods_meats"),
    autoField: "0.00",
  },
  {
    key: "タバコ",
    value: i18n.t("gics_pulldown.tobacco"),
    autoField: "14,526,670,153.4667",
  },
  {
    key: "家庭用品",
    value: i18n.t("gics_pulldown.household_products"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "パーソナルケア用品",
    value: i18n.t("gics_pulldown.personal_care_products"),
    autoField: "10,762,328,051.786",
  },
  {
    key: "ヘルスケア機器",
    value: i18n.t("gics_pulldown.health_care_equipment"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケア用品",
    value: i18n.t("gics_pulldown.health_care_supplies"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケア・ディストリビュータ",
    value: i18n.t("gics_pulldown.health_care_distributors"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケアサービス",
    value: i18n.t("gics_pulldown.health_care_services"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケア施設",
    value: i18n.t("gics_pulldown.health_care_facilities"),
    autoField: "0.00",
  },
  {
    key: "管理健康医療",
    value: i18n.t("gics_pulldown.managed_health_care"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケア・テクノロジー",
    value: i18n.t("gics_pulldown.health_care_technology"),
    autoField: "0.00",
  },
  {
    key: "バイオテクノロジー",
    value: i18n.t("gics_pulldown.biotechnology"),
    autoField: "0.00",
  },
  {
    key: "医薬品",
    value: i18n.t("gics_pulldown.pharmaceuticals"),
    autoField: "0.00",
  },
  {
    key: "ライフサイエンス・ツール/サービス",
    value: i18n.t("gics_pulldown.life_sciences_tools_services"),
    autoField: "0.00",
  },
  {
    key: "都市銀行",
    value: i18n.t("gics_pulldown.diversified_banks"),
    autoField: "57,989,211,010.7553",
  },
  {
    key: "地方銀行",
    value: i18n.t("gics_pulldown.regional_banks"),
    autoField: "57,989,211,010.7553",
  },
  {
    key: "各種金融サービス",
    value: i18n.t("gics_pulldown.diversified_financial_services"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "マルチセクター持株会社",
    value: i18n.t("gics_pulldown.multi_sector_holdings"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "専門金融",
    value: i18n.t("gics_pulldown.specialized_finance"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "商業用・住宅用不動産金融",
    value: i18n.t("gics_pulldown.commercial_residential_mortgage_finance"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "取引・決済処理サービス",
    value: i18n.t("gics_pulldown.transaction_payment_processing_services"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "消費者金融",
    value: i18n.t("gics_pulldown.consumer_finance"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "資産運用会社・資産管理銀行",
    value: i18n.t("gics_pulldown.asset_management_custody_banks"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "投資銀行・証券会社",
    value: i18n.t("gics_pulldown.investment_banking_brokerage"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "総合資本市場",
    value: i18n.t("gics_pulldown.diversified_capital_markets"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "取引所およびデータ提供会社",
    value: i18n.t("gics_pulldown.financial_exchanges_data"),
    autoField: "9,889,135,955.26565",
  },
  {
    key: "モーゲージ不動産投資信託（REIT）",
    value: i18n.t("gics_pulldown.mortgage_reits"),
    autoField: "0.00",
  },
  {
    key: "保険ブローカー",
    value: i18n.t("gics_pulldown.insurance_brokers"),
    autoField: "118,027,154,685.423",
  },
  {
    key: "生命保険・健康保険",
    value: i18n.t("gics_pulldown.life_health_insurance"),
    autoField: "118,027,154,685.423",
  },
  {
    key: "総合保険",
    value: i18n.t("gics_pulldown.multi_line_insurance"),
    autoField: "118,027,154,685.423",
  },
  {
    key: "動産保険・損害保険",
    value: i18n.t("gics_pulldown.property_casualty_insurance"),
    autoField: "118,027,154,685.423",
  },
  {
    key: "再保険",
    value: i18n.t("gics_pulldown.reinsurance"),
    autoField: "118,027,154,685.423",
  },
  {
    key: "情報技術コンサルティング・他のサービス",
    value: i18n.t("gics_pulldown.it_consulting_other_services"),
    autoField: "0.00",
  },
  {
    key: "インターネットサービスおよびインフラストラクチャー",
    value: i18n.t("gics_pulldown.internet_services_infrastructure"),
    autoField: "0.00",
  },
  {
    key: "アプリケーション・ソフトウェア",
    value: i18n.t("gics_pulldown.application_software"),
    autoField: "0.00",
  },
  {
    key: "システム・ソフトウェア",
    value: i18n.t("gics_pulldown.systems_software"),
    autoField: "0.00",
  },
  {
    key: "通信機器",
    value: i18n.t("gics_pulldown.communications_equipment"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "テクノロジー ハードウェア・コンピュータ記憶装置・周辺機器",
    value: i18n.t("gics_pulldown.technology_hardware_storage_peripherals"),
    autoField: "13,430,452,207.2979",
  },
  {
    key: "電子装置・機器",
    value: i18n.t("gics_pulldown.electronic_equipment_instruments"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "電子部品",
    value: i18n.t("gics_pulldown.electronic_components"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "電子製品製造サービス",
    value: i18n.t("gics_pulldown.electronic_manufacturing_services"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "テクノロジー ディストリビュータ",
    value: i18n.t("gics_pulldown.technology_distributors"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "半導体素材・装置",
    value: i18n.t("gics_pulldown.semiconductor_materials_equipment"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "半導体",
    value: i18n.t("gics_pulldown.semiconductors"),
    autoField: "65,678,267,366.94",
  },
  {
    key: "代替通信事業会社",
    value: i18n.t("gics_pulldown.alternative_carriers"),
    autoField: "162,246,147,531.683",
  },
  {
    key: "総合電気通信サービス",
    value: i18n.t("gics_pulldown.integrated_telecommunication_services"),
    autoField: "162,246,147,531.683",
  },
  {
    key: "無線通信サービス",
    value: i18n.t("gics_pulldown.wireless_telecommunication_services"),
    autoField: "162,246,147,531.683",
  },
  {
    key: "広告",
    value: i18n.t("gics_pulldown.advertising"),
    autoField: "0.00",
  },
  {
    key: "放送",
    value: i18n.t("gics_pulldown.broadcasting"),
    autoField: "0.00",
  },
  {
    key: "ケーブル・衛星テレビ",
    value: i18n.t("gics_pulldown.cable_satellite"),
    autoField: "0.00",
  },
  {
    key: "出版",
    value: i18n.t("gics_pulldown.publishing"),
    autoField: "0.00",
  },
  {
    key: "映画・娯楽",
    value: i18n.t("gics_pulldown.movies_entertainment"),
    autoField: "0.00",
  },
  {
    key: "インタラクティブ・ホームエンターテイメント",
    value: i18n.t("gics_pulldown.interactive_home_entertainment"),
    autoField: "0.00",
  },
  {
    key: "インタラクティブ・メディアおよびサービス",
    value: i18n.t("gics_pulldown.interactive_media_services"),
    autoField: "0.00",
  },
  {
    key: "電力",
    value: i18n.t("gics_pulldown.electric_utilities"),
    autoField: "0.00",
  },
  {
    key: "ガス",
    value: i18n.t("gics_pulldown.gas_utilities"),
    autoField: "26,505,257,885.0149",
  },
  {
    key: "総合公益事業",
    value: i18n.t("gics_pulldown.multi_utilities"),
    autoField: "0.00",
  },
  {
    key: "水道",
    value: i18n.t("gics_pulldown.water_utilities"),
    autoField: "20,747,842,949.4579",
  },
  {
    key: "独立系発電事業者・エネルギー販売業者",
    value: i18n.t("gics_pulldown.independent_power_producers_energy_traders"),
    autoField: "0.00",
  },
  {
    key: "再生エネルギー系発電事業者",
    value: i18n.t("gics_pulldown.renewable_electricity"),
    autoField: "0.00",
  },
  {
    key: "各種不動産投資信託",
    value: i18n.t("gics_pulldown.diversified_reits"),
    autoField: "0.00",
  },
  {
    key: "工業用不動産投資信託",
    value: i18n.t("gics_pulldown.industrial_reits"),
    autoField: "0.00",
  },
  {
    key: "ホテル・リゾート不動産投資信託",
    value: i18n.t("gics_pulldown.hotel_resort_reits"),
    autoField: "0.00",
  },
  {
    key: "オフィス不動産投資信託",
    value: i18n.t("gics_pulldown.office_reits"),
    autoField: "0.00",
  },
  {
    key: "ヘルスケア不動産投資信託",
    value: i18n.t("gics_pulldown.health_care_reits"),
    autoField: "0.00",
  },
  {
    key: "集合住宅用不動産投資信託",
    value: i18n.t("gics_pulldown.multi_family_residential_reits"),
    autoField: "0.00",
  },
  {
    key: "一戸建て住宅用不動産投資信託",
    value: i18n.t("gics_pulldown.single_family_residential_reits"),
    autoField: "0.00",
  },
  {
    key: "店舗用不動産投資信託",
    value: i18n.t("gics_pulldown.retail_reits"),
    autoField: "0.00",
  },
  {
    key: "その他の専門不動産投資信託",
    value: i18n.t("gics_pulldown.other_specialized_reits"),
    autoField: "0.00",
  },
  {
    key: "トランクルーム不動産投資信託",
    value: i18n.t("gics_pulldown.self_storage_reits"),
    autoField: "0.00",
  },
  {
    key: "電波塔不動産投資信託",
    value: i18n.t("gics_pulldown.telecom_tower_reits"),
    autoField: "0.00",
  },
  {
    key: "森林不動産投資信託",
    value: i18n.t("gics_pulldown.timber_reits"),
    autoField: "0.00",
  },
  {
    key: "データセンター不動産投資信託",
    value: i18n.t("gics_pulldown.data_center_reits"),
    autoField: "0.00",
  },
  {
    key: "各種不動産事業",
    value: i18n.t("gics_pulldown.diversified_real_estate_activities"),
    autoField: "0.00",
  },
  {
    key: "不動産運営会社",
    value: i18n.t("gics_pulldown.real_estate_operating_companies"),
    autoField: "0.00",
  },
  {
    key: "不動産開発",
    value: i18n.t("gics_pulldown.real_estate_development"),
    autoField: "0.00",
  },
  {
    key: "不動産サービス",
    value: i18n.t("gics_pulldown.real_estate_services"),
    autoField: "0.00",
  },
]

export const SCOPE_TYPE = [
  'Scope 1',
  'Scope 2',
  'Scope 1 & Scope 2',
  'Scope 3'
]

export const CURRENCY_TYPE = [
  "JPY",
  "USD"
]

export const DATA_TYPE = [
  {
    key: "報告データ",
    value: i18n.t("data_type.report_data")
  },
  {
    key: "エネルギー消費量",
    value: i18n.t("data_type.energy_consumption")
  },
  {
    key: "生産量",
    value: i18n.t("data_type.production_volume")
  },
  {
    key: "営業収益",
    value: i18n.t("data_type.operating_revenue")
  },
]

export const COMMERCIAL_DATA_TYPE1 = [
  {
    key: "建物の消費エネルギー",
    value: i18n.t("commercial_data_type_1.energy_consumption_of_buildings")
  },
  {
    key: "床面積毎の推定エネルギー消費量",
    value: i18n.t("commercial_data_type_1.estimated_energy_consumption_per_floor_area")
  },
  {
    key: "棟あたりの推定エネルギー消費量",
    value: i18n.t("commercial_data_type_1.estimated_energy_consumption_per_building")
  },
]

export const CAR_LOAN_TYPE = [
  {
    key: "燃料消費量",
    value: i18n.t("car_loan_type.fuel_consumption")
  },
  {
    key: "走行距離",
    value: i18n.t("car_loan_type.mileage")
  },
  {
    key: "推定走行距離",
    value: i18n.t("car_loan_type.estimated_mileage")
  },
]

export const SCORE_TYPE = [1, 2, 3, 4, 5]

export const GROUP_COMPANY_KEY = [
  'pcaf_business_group_name',
  'pcaf_business_name'
]

export const DEFAULT_YEAR = {
  MIN_YEAR: 2010,
  MAX_YEAR: new Date().getFullYear(),
  MAX_YEAR_ICP: 2030
}

export const RATE_CURRENCY = {
  '2010': 116.4,
  '2011': 111.1,
  '2012': 102.6,
  '2013': 129.6,
  '2014': 140.5,
  '2015': 134.3,
  '2016': 120.3,
  '2017': 126.6,
  '2018': 130.4,
  '2019': 122.1,
  '2020': 121.8,
  '2021': 129.8,
  '2022': 138.1,
  '2023': 151.9,
  '2024': 164.5,
  '2025': 164.5,
}