<template>
  <div v-if="$vuetify.breakpoint.width < 1024" class="footer footer-sp" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-sp" @click="goToHome">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">{{ versions }}</div>
      </div>
      <div class="to-top-sp">
        <img @click="handleToTop" class="logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
      </div>
    </v-row>
    <v-row class="footer-content-sp">
      <v-col>
        <p v-for="(item, index) in spList" :key="index">
          <a
            :href="item.href"
            target="_blank"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr" />
    <v-row class="footer-end-sp"> ©︎2022 Sustech Inc. </v-row>
  </div>
  <div v-else class="footer footer-pc" :class="isFullScreen && 'd-none'">
    <v-row class="logo-wrapper">
      <div class="logo logo-app-pc" @click="goToHome">
        <img class="logo-img" src="@/assets/icons/footer/logo.svg" alt="" />
        <div class="version">{{ versions }}</div>
      </div>
      <div class="to-top-pc">
        <span class="text-to-top-pc"> PAGE TOP </span>
        <img @click="handleToTop" class="toTop-leave logo-img back-to-top" src="@/assets/icons/to-top.svg" alt="" />
        <img
          @click="handleToTop"
          class="toTop-hover logo-img back-to-top"
          src="@/assets/icons/to-top-leave.svg"
          alt=""
        />
      </div>
    </v-row>
    <v-row class="footer-title-pc">
      <v-col>
        <p>Tools</p>
      </v-col>
      <v-col>
        <p>Settings</p>
      </v-col>
      <!-- <v-col></v-col> -->
      <v-col>
        <p>Others</p>
      </v-col>
    </v-row>
    <div class="hr-short" />
    <v-row v-if="!routersSupplier.includes($route.path)" class="footer-content-pc">
      <v-col>
        <p v-for="(item, index) in toolsList" :key="index">
          <a
            :href="item.href"
            @click.prevent="negative(item.click)"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
        <div @click.prevent="exportReport(`${routers.PRODUCTS_EMISSION}${routers.EXPORT_REPORT}`)"><p class="router-footer-end" style="margin-bottom: 10px">{{$t("footer.hyperlink_report_export")}}</p></div>
      </v-col>
      <v-col>
        <p v-for="(item, index) in settingList" :key="index">
          <a
            :href="item.href"
            @click.prevent="negative(item.click)"
            class="router-footer"
          >
            {{item.label}}
          </a>
        </p>
        <div @click="logout()"><p class="router-footer-end">{{$t("footer.hyperlink_logout")}}</p></div>
        <p></p>
      </v-col>
      <v-col>
        <p v-for="(item, index) in otherList" :key="index">
          <a
            :href="item.href"
            target="_blank"
            class="router-footer"
          >
            {{ item.label }}
          </a>
        </p>
      </v-col>
    </v-row>
    <v-row v-else class="footer-content-pc">
      <v-col>
        <p><a :href="convertQueryToUrlString(routers.SUPPLIER)" @click.prevent="negative(convertQueryToUrlString(routers.SUPPLIER))" class="router-footer">{{ $t("supplier.hyperlink_footer_primary_submission_form") }}</a></p>
      </v-col>
      <v-col>
        <p>
          <a :href="convertQueryToUrlString(routers.SETTING_LANGUAGE_SUPPLIER)" @click.prevent="negative(convertQueryToUrlString(routers.SETTING_LANGUAGE_SUPPLIER))" class="router-footer">{{$t("setting_language.title_language_settings")}}</a>
        </p>
        <div @click="logout()"><p class="router-footer-end">{{$t("footer.hyperlink_logout")}}</p></div>
      </v-col>
      <v-col>
        <p v-for="(item, index) in otherSupplierList" :key="index">
          <a
            :href="item.href"
            target="_blank"
            class="router-footer"
          >
          {{ item.label }}
          </a>
        </p>
      </v-col>
    </v-row>
    <div class="hr last-child" />
    <v-footer class="footer-section">
      <v-col cols="4"></v-col>
      <v-col cols="4" class="text-center">© 2022 Sustech Inc.</v-col>
    </v-footer>
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { logoutApi } from '@/api/auth';
import { ROUTES } from '@/router/constants';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import CommonButton from '@/components/utils/button.vue';
import { ROLE } from '@/constants/role';
import { listRouterSupplierAcceptAccess } from '@/router';
import { VERSIONS } from '@/constants/descriptions.js';
export default {
  name: 'Footer',
  components: { ErrorPopup, CommonButton },
  data() {
    return {
      showLogo: ['/home', '/managements/company', '/managements/contractors'],
      dialog: false,
      linkTexts: ['運営会社', '利用規約', 'プライバシーポリシー'],
      routers: ROUTES,
      errorPopup: false,
      errorMessage: '',
      routersSupplier: listRouterSupplierAcceptAccess,
      versions: VERSIONS
    };
  },
  
  props: {
    drawer: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('userData', ['contractor', 'currentUser', 'isInProductPage']),
    ...mapState('registerData', ['isFullScreen']),
    isAdminRole() {
      return ROLE.ADMIN === this.currentUser?.user?.role_id;
    },
    isApprovalRole() {
      return ROLE.APPROVAL === this.currentUser?.user?.role_id;
    },
    isUserRole() {
      return ROLE.USER === this.currentUser?.user?.role_id;
    },
    toolsList() {
      const list = [
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          label: this.$t("b_list_product.title_list_products"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.REGISTER_PRODUCT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.REGISTER_PRODUCT}`,
          label: this.$t("left_menu.button_registration_product"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
          label: this.$t("left_menu.button_application_status"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          click: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
          label: this.$t("footer.hyperlink_approve"),
          disabled: this.isUserRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.OTHERS}/${ROUTES.OFFSET_TRANSACTION}`,
          label: this.$t("footer.hyperlink_offset_transaction"),
          disabled: false,
        },
      ];
      return list.filter(item => !item.disabled);
    },
    settingList() {
      const list = [
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
          label: this.$t("footer.hyperlink_setting_top"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.USER_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.USER_MANAGEMENT}`,
          label: this.$t("footer.hyperlink_user_management"),
          disabled: !this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.FACILITY_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.FACILITY_MANAGEMENT}`,
          label: this.$t("footer.hyperlink_facility_management"),
          disabled: !this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.VIEWER_MANAGEMENT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.VIEWER_MANAGEMENT}`,
          label: this.$t("footer.hyperlink_view_authority_management"),
          disabled: !this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CALCULATION_TERM}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CALCULATION_TERM}`,
          label: this.$t("footer.hyperlink_year_setting"),
          disabled: !this.isAdminRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          label: this.$t("footer.hyperlink_customized_db_management"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_AUTO_APPROVAL}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_AUTO_APPROVAL}`,
          label: this.$t("setting.button_setting_auto_approval"),
          disabled: this.isUserRole,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_LANGUAGE}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING_LANGUAGE}`,
          label: this.$t("setting.button_setting_language"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CHANGE_PASSWORD_USER}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CHANGE_PASSWORD_USER}`,
          label: this.$t("footer.hyperlink_change_password"),
          disabled: false,
        },
        {
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CSV_FORMAT}`,
          click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CSV_FORMAT}`,
          label: this.$t("footer.hyperlink_csv_format"),
          disabled: false,
        },
        // {
        //   href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.LINKED_SERVICE_MANAGEMENT}`,
        //   click: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.LINKED_SERVICE_MANAGEMENT}`,
        //   label: this.$t("linked_service_management.button_linked_service_management_setting"),
        //   disabled: !this.isAdminRole,
        // },
      ];
      return list.filter(item => !item.disabled);
    },
    otherList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t("footer.hyperlink_faqs"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
        {
          href: "/others/versions",
          label: this.$t("footer.hyperlink_versions"),
        },
      ]
    },
    otherSupplierList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}'/'${ROUTES.TERM_OF_USER}`),
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}'/'${ROUTES.PRIVACY}`),
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
        {
          href: "/others/versions",
          label: this.$t("footer.hyperlink_versions"),
        },
      ]
    },
    spList() {
      return [
        {
          href: "https://sustech-inc.co.jp/carbonix/",
          label: this.$t("footer.hyperlink_carbonix_official_site"),
        },
        {
          href: process.env.VUE_APP_FAQS_LINK,
          label: this.$t("footer.hyperlink_faqs"),
        },
        {
          href: "https://sustech-inc.co.jp/",
          label: this.$t("footer.hyperlink_operating_company"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`),
          label: this.$t("footer.hyperlink_term_of_use"),
        },
        {
          href: this.convertQueryToUrlString(`${ROUTES.OTHERS}/${ROUTES.PRIVACY}`),
          label: this.$t("footer.hyperlink_privacy_policy"),
        },
        {
          href: "https://www.truste.or.jp/hssl/validate/01632.php",
          label: this.$t("footer.hyperlink_truste_certified_page"),
        },
      ]
    }
  },

  methods: {
    handleToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleLogout() {
      logoutApi().then(() => this.$router.push({ path: '/auth/login' }));
    },

    isShowLogo() {
      return this.showLogo.includes(this.$route.path) ? true : false;
    },
    redirectToEmissionMenu() {
      this.$router.push({ path: ROUTES.LIST_MENU_EMISSION }).catch((err) => {});
    },

    redirectToTemplate() {
      this.$router.push({ path: ROUTES.LIST_EMISSION_TEMPLATES }).catch((err) => {});
    },
    async exportReport() {
      return this.$router.push({path: ROUTES.PRODUCTS_EMISSION + ROUTES.EXPORT_REPORT})
    },
    logout() {
      this.$emit('logout');
    },
    negative(url) {
      this.handleToTop();
      if (!url) {
        return;
      }
      if (this.$route.path === url) {
        this.$router.go();
      } else {
        this.$router.push({ path: url });
      }
    },
    convertQueryToUrlString(url) {
      let params = '';
      let querySize = 0;
      for (const [key, value] of Object.entries(this.$route?.query)) {
        querySize += 1;
        if (!params.includes(key)) {
          params += (querySize === Object.keys(this.$route?.query)?.length) ? `${key}=${value}` : `${key}=${value}&`;
        }
      }
      return `${url}?${params}`;
    },
    goToHome() {
      if (this.$route.path.includes(ROUTES.SUPPLIER) || this.$route?.query?.primary_uuid) {
        this.$router.push({ path: ROUTES.SUPPLIER, query: { primary_uuid: this.$route?.query?.primary_uuid } });
      } else {
        this.$router.push({ path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}` })
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/components/defaultLayout/footerStyles/index';

.back-to-top {
  cursor: pointer;
}

.footer-sp {
  height: max-content;
  display: block;
  margin-top: 80px;
  .logo-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: unset;
    padding: 40px 35px 0 20px;
    .version {
      color: $monoDark;
      font-size: 12px;
      margin-top: 4px;
      cursor: default;
    }
  }
  .logo-app-sp {
    img {
      width: 140px;
      height: 18px;
    }
    display: flex;
    gap: 10px;
  }
  .logo-app-sp,
  .to-top-sp {
    display: flex;
    align-items: flex-start;
  }

  .footer-content-sp {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    margin-top: 36px;
    margin-bottom: 25px;
    margin-left: 0;
    padding: 0 20px;
    .col {
      padding: unset;
      p {
        margin-bottom: 17px;
      }
    }
  }

  .footer-end-sp {
    justify-content: center;
    padding: 9px 0px 11px;
    margin: 0;
    color: #99a6a9;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
  .hr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}

.router-footer {
  color: #7c898c;
  text-decoration: none;
}

.router-footer:hover {
  cursor: pointer;
  color: #e4e6e7;
  background: $bgDark;
}

.router-footer-end:hover {
  color: #e4e6e7;
  background: $bgDark;
  padding: 0px 8px 0px;
  margin-left: -8px;
  cursor: pointer;
}

.back-to-top {
  cursor: pointer;
}
.footer-pc {
  height: auto;
  display: block;
  margin-top: 80px;

  .logo-wrapper {
    padding: 43px 40px 0;
    margin: unset;
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    .version {
      color: $monoDark;
      font-size: 12px;
      margin-top: 4px;
      cursor: default;
    }
  }
  .logo-app-pc {
    cursor: pointer;
    img {
      width: 140px;
      height: 18px;
    }
    display: flex;
    gap: 10px;
  }

  .to-top-pc {
    display: flex;
    align-items: center;
    img {
      margin-left: 16px;
    }
    .toTop-hover {
      display: none;
    }
  }
  .to-top-pc:hover {
    .toTop-hover {
      display: block;
    }
    .toTop-leave {
      display: none;
    }
  }

  .text-to-top-pc {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: $goldMid;
  }

  .footer-title-pc {
    width: 87.17%;
    color: $goldLight;
    font-family: 'Century Gothic Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: unset;
    padding: 0 0px 12px 40px;
    .col {
      padding: unset;
      P {
        margin-bottom: unset;
        font-family: 'Century Gothic Pro';
      }
    }
  }

  .footer-content-pc {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    width: 87.17%;
    padding: 16px 0px 0 40px;
    &.row {
      margin: unset;
      .col {
        padding: unset;
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 42px;
          }
        }
      }
    }
  }
  .hr {
    margin: 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
  .hr-short {
    margin: 0 144px 0 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    &.last-child {
      margin: unset;
    }
  }
}
.footer-section {
  background: #132d39;
  color: #99a6a9;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding: 10px;
  height: 44px;
}

#customers th {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  color: #a4a184;
}

#customers td {
  color: #7c898c;
}

.footer {
  position: relative !important;
}
.text-right {
  height: 57px;
  text-align: right;
  padding: 0;
}
</style>
