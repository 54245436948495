<template>
  <v-app v-if="$vuetify.breakpoint.width < 1024" id="inspire-mobile" style="overflow: hidden" :class="$i18n.locale">
    <v-navigation-drawer v-model="drawer" app :width="isExpanded ? '100%' : 70" :touchless="true">
      <!-- ################################################################## -->
      <div class="menu-title top">Menu</div>
      <aside :class="[isExpanded ? 'is-expanded' : 'is-not-expanded']" class="expanded-mobile">
        <div class="menu menu-top" :class="{'disable-menu': isDisableMenu}">
          <div class="mn-g1 lable button-menu-sp" v-for="(menu, i) in menuTop" :key="i">
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
              </template>
            </div>
            <span
              class="tooltip-hover text overflow-eclipsis-text"
              style="width: -webkit-fill-available"
              :id="menu.id"
            >{{ menu.name }}
              <div class="tooltip-custom-info-mobile" v-show="showEllipsisTip[menu.id]">
                <p>{{ menu.name }}</p>
              </div>
            </span>
          </div>
        </div>
        
        <TypeSelection :class="{'disable-menu': isDisableMenu}" :isMobile="true" :isExpanded="isExpanded" />
        <!-- menu chart -->
        <div class="menu menu-top mt-4 bb-custome" :class="{'disable-menu': isDisableMenu}">
          <a
            class="button button-menu-sp"
            :class="[
              (isEmissionDetail(i) ||
              isRegisterData(i) ||
              isViewData(i) ||
              menu.link === $route.path ||
              isActivePath(menu.pathActives)
              ) &&
                'router-link-exact-active router-link-active',
            ]"
            v-for="(menu, i) in menuChart"
            :key="i"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text" :style="obJectStylesLabelMenu(menu)">{{ menu.name }}</span>
            <div v-if="menu.status" class="number-status">{{ menu.status }}</div>
          </a>
        </div>
        <hr class="menu-divider" />

        <div class="menu menu-top mt-4 bb-custome" :class="{'disable-menu': isDisableMenu}">
          <a
            class="button button-menu-sp"
            :class="[
              (menu.link === $route.path ||
              isActivePath(menu.pathActives) ||
              (i === 0 && parseInt($route.params.scope || '') && !$route.path.includes('/view'))
              ) &&
                'router-link-exact-active router-link-active',
            ]"
            v-for="(menu, i) in menuMain"
            :key="i"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :id="menu.id"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text" :style="obJectStylesLabelMenu(menu)">{{ menu.name }}</span>
            <div v-if="menu.status" class="number-status">{{ menu.status }}</div>
          </a>
        </div>

        <div class="menu menu-top mt-4 bb-custome" :class="{'disable-menu': isDisableMenu}">
          <a
            class="button button-menu-sp"
            :class="[
              (menu.link === $route.path ||
              isActivePath(menu.pathActives)
              ) &&
                'router-link-exact-active router-link-active',
            ]"
            v-for="(menu, i) in menuOther"
            :key="i"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text" :style="obJectStylesLabelMenu(menu)">{{ menu.name }}</span>
            <div v-if="menu.status" class="number-status">{{ menu.status }}</div>
          </a>
        </div>

        <div class="menu menu-middle mt-4" :class="{'disable-menu': isDisableMenu}">
          <a
            class="button button-menu-sp"
            v-for="(menu, i) in menuMiddle"
            :key="i"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[(menu.link === $route.path || isSetting(i) || isActiveSubLink(menu)) && 'router-link-exact-active router-link-active']"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </a>
        </div>
        <div class="menu menu-bottom" :class="{'disable-menu': isDisableMenu}">
          <button
            class="button button-menu-sp"
            v-for="(menu, i) in menuBottom"
            :key="i"
            @click="
              dialog = true;
              drawer = false;
            "
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </button>
        </div>
        <div class="flex"></div>
      </aside>
      <!-- ################################################################## -->
    </v-navigation-drawer>

    <v-app-bar app style="height: 84px !important; z-index: 99;">
      <v-toolbar-title class="header header-sp">
        <div class="header-wrapper" @click="toHomePage">
          <img class="logo-img" src="../assets/icons/User.svg" alt="" />
        </div>
        <!-- TODO: action table -->
        <!-- <undo-redo /> -->
      </v-toolbar-title>
      <div class="breadcrumb-sp">
        <breadcrumbs :items="breadCrumbText" />
      </div>
    </v-app-bar>
    <v-main>
      <div
        class="content-main"
        :id="this.$route.name"
        :class="[
          this.$route.name === 'Change password' ? 'changPass_mb_height' : '',
          $route.name === 'Reduction Target' ? 'reduction-list' : '',
          isFullScreen && 'table-full-screen',
        ]"
      >
        <router-view />
        <div class="menu-toggle-sp">
          <button-app
            @action="openMenu(true)"
            v-if="!drawer"
            className="toggle-btn"
            icon="menuSp.svg"
            iconHover="menuSpHover.svg"
          />
        </div>
        <div class="menu menu-toggle-wrap" v-if="drawer && closeIcon">
          <div class="close-btn-block">
            <div id="menu-close-icon" class="menu-icon" @click="openMenu(false)">
              <img class="menu-close" src="@/assets/icons/close-menu.svg" alt="" />
              <img class="menu-close-hover" src="@/assets/icons/close-menu-hover.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <footer-layout :drawer="drawer" app />
    </v-main>

    <dialog-popup
      :dialog="dialog"
      :message="messageLogout"
      :logout="true"
      @close="dialog = false"
      @logout="handleLogout"
      :closeText="closeText"
    />
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
  </v-app>
  <v-app v-else id="inspire" :class="$i18n.locale">
    <v-navigation-drawer
      permanent
      v-model="drawer"
      app
      :style="isExpanded ? 'transform: translateX(0%);' : 'transform: translateX(-100%);'"
      class="navigator"
      :touchless="true"
      id="mnExpend"
    >
      <!-- ################################################################## -->
      <aside class="is-expanded styled-scroll-left-menu" :class="{'disable-menu': isDisableMenu}">
        <div class="logo" @click="toHomePage">
          <template>
            <img class="logo-img" src="../assets/icons/User.svg" alt="" />
            <img class="logo-img" src="../assets/icons/IX.svg" alt="" />
          </template>
        </div>

        <!-- menu chart -->
        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, i) in menuChart"
            :key="i"
            class="button btn-leftmenu"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              i > 0 && 'margin-top-4',
              (isEmissionDetail(i) ||
              isRegisterData(i) ||
              isViewData(i) ||
              menu.link === $route.path ||
              isActivePath(menu.pathActives)
              ) &&
                'router-link-exact-active router-link-active',
            ]"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </a>
        </div>

        <hr class="menu-divider mt-4" />
        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, i) in menuMain"
            :key="i"
            class="button btn-leftmenu"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              i > 0 && 'margin-top-4',
              (menu.link === $route.path ||
              isActivePath(menu.pathActives) ||
              (i === 0 && parseInt($route.params.scope || '') && !$route.path.includes('/view'))
              ) &&
                'router-link-exact-active router-link-active',
            ]"
            :id="menu.id"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text" :style="obJectStylesLabelMenu(menu)">{{ menu.name }}</span>
            <div v-if="menu.status" class="number-status">{{ menu.status }}</div>
          </a>
        </div>

        <hr class="menu-divider mt-4" />
        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, i) in menuOther"
            :key="i"
            class="button btn-leftmenu"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              i > 0 && 'margin-top-4',
              (menu.link === $route.path ||
              isActivePath(menu.pathActives)
              ) &&
                'router-link-exact-active router-link-active',
            ]"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </a>
        </div>

        <hr class="menu-divider mt-4" />
        <!-- <div class="menu-title">
          <h1 class="menu-title-text">Others</h1>
        </div> -->
        <div class="menu menu-middle mt-4">
          <a
            class="button"
            v-for="(menu, i) in menuMiddle"
            :key="i"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="menu.link === $route.path || (isSetting(i) && !$route.path.includes('/emissions/view')) || isActiveSubLink(menu) ? 'router-link-exact-active router-link-active' : ''"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons first" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons last" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </a>
        </div>
        <div class="flex"></div>
        <div class="menu menu-bottom" style="margin-bottom: 16px">
          <button class="button" v-for="(menu, i) in menuBottom" :key="i" @click="dialog = true">
            <div class="menu-icon">
              <template>
                <img class="material-icons first" :src="getSettingIcon(menu.icon)" alt="" />
                <img class="material-icons last" :src="getSettingIcon(menu.iconActive)" alt="" />
              </template>
            </div>
            <span class="text">{{ menu.name }}</span>
          </button>
        </div>
        <hr class="menu-divider" />
        <div class="menu bottom-btn" @click="toggleMenu">
          <button class="button menu-toggle">
            <div class="menu-icon-last">
              <template>
                <img class="material-icons" src="../assets/icons/expandToggleClose.svg" alt="" />
                <img class="material-icons" src="../assets/icons/expandToggleClose_active.svg" alt="" />
              </template>
            </div>
            <span class="text-menu-mini-pc">{{ $t("left_menu.button_close_menu")}}</span>
          </button>
        </div>
      </aside>
      <!-- ################################################################## -->
    </v-navigation-drawer>

    <v-navigation-drawer
      permanent
      v-model="drawer"
      v-if="!isExpanded"
      app
      style="max-width: 74px !important"
      class="navigator"
    >
      <!-- ################################################################## -->
      <aside class="is-expanded is-expanded-mini styled-scroll-left-menu">
        <div class="logo" @click="toHomePage">
          <template>
            <img class="logo-img" src="../assets/icons/IX.svg" alt="" />
          </template>
        </div>
        <!-- menu chart -->
        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, menuIndex) in menuChart"
            :key="menuIndex"
            class="button button-mini"
            :style="[menuIndex > 0 ? 'margin-top: 4px !important' : '']"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              (isEmissionDetail(menuIndex) ||
              menu.link === $route.path ||
              isActivePath(menu.pathActives) ||
              (menuIndex === 2 && parseInt($route.params.scope || '') && !$route.path.includes('/view'))
              ) &&
                'router-link-exact-active router-link-active',
            ]"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" :alt="menu.name" :title="menu.name" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" :title="menu.name" />
              </template>
            </div>
          </a>
        </div>
        <hr class="menu-divider mt-4" />

        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, menuIndex) in menuMain"
            :key="menuIndex"
            class="button button-mini"
            :style="[menuIndex > 0 ? 'margin-top: 4px !important' : '']"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              (menu.link === $route.path ||
              isActivePath(menu.pathActives) ||
              (menuIndex === 2 && parseInt($route.params.scope || '') && !$route.path.includes('/view'))
              ) &&
                'router-link-exact-active router-link-active',
            ]"
            :id="menu.id"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" :alt="menu.name" :title="menu.name" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" :title="menu.name" />
              </template>
            </div>
            <div v-if="menu.status" class="number-status-button-mini">{{ menu.status }}</div>
          </a>
        </div>
        <hr class="menu-divider mt-4" />

        <div class="menu menu-top mt-4">
          <a
            v-for="(menu, menuIndex) in menuOther"
            :key="menuIndex"
            class="button button-mini"
            :style="[menuIndex > 0 ? 'margin-top: 4px !important' : '']"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="[
              (menu.link === $route.path ||
              isActivePath(menu.pathActives) ||
              (menuIndex === 2 && parseInt($route.params.scope || '') && !$route.path.includes('/view'))
              ) &&
                'router-link-exact-active router-link-active',
            ]"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" :alt="menu.name" :title="menu.name" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" :title="menu.name" />
              </template>
            </div>
          </a>
        </div>
        <hr class="menu-divider mt-4" />

        <div class="menu menu-middle mt-4">
          <a
            v-for="(menu, i) in menuMiddle"
            :key="i"
            class="button button-mini"
            :style="[i > 0 ? 'margin-top: 4px !important' : '']"
            :href="menu.link"
            @click.prevent="redirect(menu.link)"
            :class="menu.link === $route.path || isSetting(i) || isActiveSubLink(menu) ? 'router-link-exact-active router-link-active' : ''"
          >
            <div class="menu-icon">
              <template>
                <img class="material-icons" :src="getSettingIcon(menu.icon)" :alt="menu.name" :title="menu.name" />
                <img class="material-icons" :src="getSettingIcon(menu.iconActive)" alt="" :title="menu.name" />
              </template>
            </div>
          </a>
        </div>
        <div class="flex"></div>
        <div class="menu" style="margin-bottom: 16px">
          <button class="button button-mini" @click="dialog = true">
            <div class="menu-icon">
              <template>
                <img class="material-icons first" src="../assets/icons/logoutIcon.svg" alt="" :title="$t('type_selection.button_log_out')" />
                <img class="material-icons last" src="../assets/icons/logoutIconActive.svg" alt="" :title="$t('type_selection.button_log_out')" />
              </template>
            </div>
          </button>
        </div>
        <hr class="menu-divider" />
        <div class="menu menu-bottom" style="margin: 8px 0px" @click="toggleMenu">
          <button class="button button-expand">
            <div class="menu-icon-last" style="justify-content: center">
              <template>
                <img src="../assets/icons/expandIcon.svg" alt="" :title="$t('type_selection.button_open_menu')" />
                <img src="../assets/icons/expandIcon.svg" alt="" :title="$t('type_selection.button_open_menu')" />
              </template>
            </div>
          </button>
        </div>
      </aside>
    </v-navigation-drawer>
    <TypeSelection :isExpanded="isExpanded" />
    <div class="user-info" icon>
      <div class="user-info__contractor">
        <img class="icons-header user-info__contractor--icon" src="@/assets/icons/address.svg" alt="" />
        <span class="text-header overflow-eclipsis-text" id="contractorName">{{ contractorName}}</span>
        <div class="tooltip-custom-info" v-if="showEllipsisTip.contractorName">{{ contractorName }}</div>
      </div>
      <div class="user-info__name">
        <img class="icons-header user-info__name--icon" src="@/assets/icons/avatar.svg" alt="" />
        <span class="text-header overflow-eclipsis-text" id="userName">{{ userName }}</span>
        <div class="tooltip-custom-info" v-if="showEllipsisTip.userName">{{ userName }}</div>
      </div>
      <v-menu offset-y offset-x nudge-bottom="6">
        <template v-slot:activator="{ on, attrs }">
          <div class="option-icon" v-bind="attrs" v-on="on">
            <img src="@/assets/icons/options.svg" alt="Options icon" />
          </div>
        </template>
        <v-list class="option-list">
          <v-list-item v-for="(menu, index) in menuHeader" :key="index" class="option-item">
            <button
              v-if="menu.isLogout"
              class="option-item-link"
              @click="
                dialog = true;
                drawer = false;
              "
            >
              <div class="menu-icon">
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
              </div>
              <span class="text">{{ menu.name }}</span>
            </button>
            <a
              v-else
              class="option-item-link"
              :href="menu.link"
              @click.prevent="redirect(menu.link)"
              :class="[menu.link === $route.path && 'router-link-exact-active router-link-active']"
            >
              <div class="menu-icon">
                <img class="material-icons" :src="getSettingIcon(menu.icon)" alt="" />
              </div>
              <span class="text">{{ menu.name }}</span>
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-app-bar
      style="min-height: 84px; overflow: hidden; z-index: 99;"
      app
      :style="
        isExpanded
          ? 'transform: translateX(163px);left: 73px !important'
          : 'transform: translateX(0px); left: 73px !important'
      "
      class="header-wrapper-pc"
    >
      <div class="header-wrapper">
        <v-toolbar-title class="header undo-redo">
          <!-- <undo-redo /> -->
        </v-toolbar-title>
        <v-toolbar-title icon class="account-name hidden">
          <div>
            <img class="icons-header" src="@/assets/icons/address.svg" alt="" />
            <span class="text-header">{{ contractorName }} </span>
          </div>
          <div>
            <img class="icons-header" src="@/assets/icons/avatar.svg" alt="" />
            <span class="text-header">{{ userName }}</span>
          </div>
        </v-toolbar-title>
      </div>
      <div class="breadcrumb-pc">
        <breadcrumbs :items="breadCrumbText" />
      </div>
    </v-app-bar>

    <v-main :class="isFullScreen && 'table-full-screen'"
            :style="isExpanded ? 'padding-left: 236px !important' : 'padding-left: 73px !important'">
      <div class="content-main" 
        :class="[
          this.$route.name === 'Change password' ? 'changPass_pc_height' : '', 
          this.$route.name === 'otherSetting' ? 'other-setting' : '',
          $route.name === 'Reduction Target' ? 'reduction-list' : '',
        ]">
        <router-view />
      </div>
      <footer-layout :drawer="drawer" app @logout="dialog = true" />
    </v-main>

    <dialog-popup
      :dialog="dialog"
      :message="messageLogout"
      :logout="true"
      @close="dialog = false"
      @logout="handleLogout"
      :closeText="closeText"
    />
    <error-popup :dialog="errorPopup" :message="errorMessage" @submit="errorPopup = false" />
    <LoadingScrren v-if="isLoadingCommon"/>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs";
import FooterLayout from "@/components/defaultLayout/footer";
import DialogPopup from "@/components/dialogs/question-popup.vue";
import ErrorPopup from "@/components/dialogs/error-popup.vue";
import webStorage from "@/utils/webStorage";
import { logoutApi, getUserInfo, updateLastAction } from "@/api/auth";

import { ROLE } from "@/constants/role";
import { ROUTES } from "@/router/constants";
import { ACCESS_TOKEN } from "@/constants/configs";
import { TIMEOUT_KEY, TIME_CHECK_STEP, TIME_CHECK_INTERVAL, DEFAULT_TIMEOUT } from "@/constants/refreshToken";
import {
  NOT_ACTIVE_TIMEOUT_KEY,
  NOT_ACTIVE_TIMEOUT,
  ACTIVE_CHECK_TIME_STEP,
  INTERVAL_CHECKING_TIME
} from "@/constants/refreshSession";

import { CHECK_TIME_NOT_ACTIVE_KEY, TIME_NOT_ACTIVE } from "@/constants/notActiveKey";

import ButtonApp from "@/components/commonApp/ButtonCustom";
import { reLocateDashHeader } from "@/api/others";
import { getExportReport } from "@/api/exportLog";
import moment from "moment";
import { getDurations } from "@/api/duration";
import TypeSelection from '@/components/type-selection.vue';
import { getStartMonth } from "@/api/duration";
import LoadingScrren from "@/components/commonApp/LoadingScrren.vue";
export default {
  components: {
    Breadcrumbs,
    FooterLayout,
    DialogPopup,
    ButtonApp,
    ErrorPopup,
    TypeSelection,
    LoadingScrren
  },
  data() {
    return {
      showEllipsisTip:{
        userName: false,
        contractorName: false,
      },
      drawer            : null,
      closeIcon         : false,
      isExpanded        : true,
      menuHeader: [
        {
          name      : this.$t("left_menu.button_faqs"),
          link      : process.env.VUE_APP_FAQS_LINK,
          icon      : "faq-icon.svg",
        },
        {
          name      : this.$t("left_menu.button_contact"),
          link      : "/contact/",
          icon      : "contact.svg",
        },
        {
          name      : this.$t("left_menu.button_logout"),
          link      : "",
          icon      : "logoutIcon.svg",
          isLogout  : true,
        }
      ],
      menuTop           : [
        {
          name      : "",
          link      : "",
          icon      : "address.svg",
          iconActive: "address.svg"
        },
        {
          name      : "",
          link      : "",
          icon      : "avatar.svg",
          iconActive: "avatar.svg"
        }
      ],
      menuMain          : [],
      menuChart: [
        {
          name: this.$t("left_menu.button_dashboard"),
          link: ROUTES.DASHBOARD,
          icon: "dashboard.svg",
          iconActive: "dashboard_active.svg",
          pathActives: []
        },
        {
            name: this.$t("left_menu.button_reduction_target"),
            link: ROUTES.PRODUCT_REDUCTION,
            icon: "reduction.svg",
            iconActive: "reduction_active.svg",
            pathActives: ['reduction/setting']
        },
        {
          name: this.$t("left_menu.button_list_emission"),
          // link: ROUTES.LIST_EMISSIONS,
          link: `${ROUTES.VIEW_EMISSION}/1/1`, // Default to the start screen with category 1 and scope 1
          icon: "register.svg",
          iconActive: "register_active.svg",
          pathActives: ['emissions/view']
        },
      ],
      menuOther: [
        {
          name: this.$t("left_menu.button_offset_trading"),
          link: ROUTES.OTHERS + "/" + ROUTES.OFFSET_TRANSACTION,
          icon: "offsetTradingIcon.svg",
          iconActive: "offsetTradingIcon_active.svg"
        },
        {
          name: this.$t("left_menu.button_export_report"),
          link: ROUTES.EXPORT_REPORT,
          icon: "exportIcon.svg",
          iconActive: "exportIcon_active.svg",
          pathActives: []
        },
      ],
      menuMiddle        : [
        {
          name      : this.$t("left_menu.button_setting"),
          link      : "/setting",
          icon      : "settingIcon.svg",
          iconActive: "settingIconActive.svg"
        },
        {
          name      : this.$t("left_menu.button_faqs"),
          link      : process.env.VUE_APP_FAQS_LINK,
          icon      : "faq-icon.svg",
          iconActive: "faq-icon.svg",
        },
        {
          name      : this.$t("left_menu.button_contact"),
          link      : "/contact/",
          sublink      : "/contact",
          icon      : "contact.svg",
          iconActive: "contactActive.svg"
        }
      ],
      menuBottom        : [
        {
          name      : this.$t("left_menu.button_logout"),
          link      : "",
          icon      : "logoutIcon.svg",
          iconActive: "logoutIconActive.svg"
        }
      ],
      items             : [
        {
          id   : 1,
          title: "Setting"
        },
        {
          id   : 2,
          title: "Logout"
        }
      ],
      selectOptions     : [],
      disabledContractor: [ROUTES.HOME_DASHBOARD],
      lockSidebar       : [ROUTES.HOME_DASHBOARD],
      dialog            : false,
      idInternal        : null,
      activeInternal    : null,
      disabledUserRole  : [
        ROUTES.USER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.CALCULATION_TERM
      ],
      isAndroidMobile   : false,
      errorPopup        : false,
      errorMessage      : "",
      userName          : "",
      contractorName    : "",
      startMonth: null
    };
  },
  computed: {
    ...mapState("commonApp", ["breadCrumbText", "isLoadingCommon"]),
    ...mapState("userData", ["contractor", "currentUser",'planType', 'isInProductPage']),
    ...mapState("registerData", ["isFullScreen"]),
    ...mapState("workflowData", ["dataNotification"]),
    ...mapState("settingApp", ["isDisableMenu"]),
    isRoleUser() {
      return this.currentUser?.user?.role_id === ROLE.USER;
    },
    checkAuth() {
      return webStorage.get(ACCESS_TOKEN);
    },
    messageLogout() {
      return this.$t("left_menu.description_logout")
    },
    closeText() {
      return this.$t('popup.button_cancel')
    },
    obJectStylesLabelMenu() {
      return (menu) => {
        if(menu?.status) {
          return {
          'max-width': '126px',
          }
        }
      }
    },
  },
  async mounted() {
    window.addEventListener('storage', (event) =>  {
      if(event.key === 'vuex' && event.newValue) {
        const newValue = JSON.parse(event.newValue);
        if(newValue.userData?.language && newValue.userData?.language?.code !== this.$i18n.locale) {
          location.reload();
          return
        }
      }
    })
    this.updateMenuChart();
    await this.actionUpdateIsFullScreen(false);
    await this.actionUpdateIsExpand(true);
    // await this.$store.dispatch("userData/checkRefreshToken");
    // this.resetTimeExpiredApp();
    // this.handleLogoutAndRedirectHomeAfterExpired();
    // this.handleLogoutAndRedirectLogin();
    // this.onDocumentEvent();
    this.updateLastAction();

    if (document.getElementById("mnNotExpanded")) {
      document.getElementById("mnNotExpanded").style.display = "none";
    }

    // Handle auto logout
    // set time active default
    webStorage.set(CHECK_TIME_NOT_ACTIVE_KEY, TIME_NOT_ACTIVE);

    //mark active state when doing click
    document.addEventListener("click", function() {
      webStorage.set(CHECK_TIME_NOT_ACTIVE_KEY, TIME_NOT_ACTIVE);
    });

    //mark active state when doing scroll
    document.addEventListener("scroll", function() {
      webStorage.set(CHECK_TIME_NOT_ACTIVE_KEY, TIME_NOT_ACTIVE);
    });
  },
  updated(){
   this.checkEllipsisActive();
  },
  watch  : {
    dataNotification: {
      handler() {
        this.menuMain = [
          {
            name: this.$t("left_menu.button_registration"),
            link: ROUTES.EMISSIONS,
            icon: "emissionRegisterIcon.svg",
            iconActive: "emissionRegisterIcon_active.svg",
            pathActives: ["register/list-menu", "emissions/list-menu"],
            id: 'registration-data',
          },
          {
            name: this.$t("left_menu.button_template"),
            link: "/emissions/" + ROUTES.LIST_EMISSION_TEMPLATES,
            icon: "template.svg",
            iconActive: "template_active.svg",
            pathActives: ["register/list-templates"],
            id: 'template-data',
          },
          {
            name: this.$t("left_menu.button_application_status"),
            link: "/emissions/" + ROUTES.LIST_EMISSION_SUBMITTED,
            icon: "appStatus.svg",
            iconActive: "appStatus_active.svg",
            status: this.dataNotification?.submissionNotice,
            pathActives: ["register/list-submitted"],
            id: 'submitted-data',
          },
          {
            name: this.$t("left_menu.button_approval"),
            link: ROUTES.APPROVAL,
            icon: "approval.svg",
            iconActive: "approval_active.svg",
            status: this.dataNotification?.approvalNotice,
            pathActives: ["register/approval"],
            isDivide: true,
            id: 'approve-data',
          },
          {
            name: this.$t("left_menu.button_primary_data_merge"),
            link: ROUTES.PRIMARY_DATA_MERGE,
            icon: "primary_data_merge.svg",
            iconActive: "primary_data_merge_active.svg",
            status: this.dataNotification?.approvalPrimary,
            pathActives: ["primary-data/list-form",'/link-to-primary/edit-form',ROUTES.CREATE_FORM_LINK_PRIMARY_DATA , ROUTES.PRIMARY_DATA_MERGE],
            isDivide: true,
            id: 'primary-data'
          },
        ]
        if (this.currentUser !== undefined) {
          if(this.currentUser.user.role_id === ROLE.USER) {
            const existApprovalIndex = this.menuMain.findIndex(menu => menu.name === this.$t('left_menu.button_approval'));
            this.menuMain.splice(existApprovalIndex, 1);
          }
          if( this.currentUser.user.role_id !== ROLE.ADMIN || !this.planType.is_primary_data) {
            const existPrimaryIndex = this.menuMain.findIndex(menu => menu.name === this.$t('left_menu.button_primary_data_merge'));
            this.menuMain.splice(existPrimaryIndex, 1);
          }
        }
      },
      deep: true,
      immediate: true
    },
    "$vuetify.breakpoint.width": {
      handler() {
        if (this.$vuetify.breakpoint.width > 800) {
          this.isExpanded = true;
        }
      }
    },
    "currentUser.user"         : {
      handler(currentUser) {
        if (currentUser !== undefined) {
          if(this.currentUser.user.role_id === ROLE.USER) {
            const existApprovalIndex = this.menuMain.findIndex(menu => menu.name === this.$t('left_menu.button_approval'));
            this.menuMain.splice(existApprovalIndex, 1);
          }
          if( this.currentUser.user.role_id === ROLE.USER || this.currentUser.user.role_id === ROLE.APPROVAL) {
            const existPrimaryIndex = this.menuMain.findIndex(menu => menu.name === this.$t('left_menu.button_primary_data_merge'));
            this.menuMain.splice(existPrimaryIndex, 1);
          }
        }

      },
      immediate: true
    },
    $route                     : {
      handler  : async function(url, oldUrl) {
        this.actionUpdateIsLoading(false)
        if (url?.name !== oldUrl?.name) {
          await this.checkUserInfo();
          this.getNotification();
        }
        this.actionUpdateIsFullScreen(false);
        if (this.isRoleUser === true) {
          if (this.disabledUserRole?.includes(url.path)) {
            this.$router.push({ path: "/setting" });
          }
        }
        if(oldUrl?.name && oldUrl?.name === 'NewRegisterData' && url?.name !== 'NewRegisterData') {
          sessionStorage.setItem("title", "");
          sessionStorage.setItem("attribution_factor_setting", "");
        }
      },
      deep     : true,
      immediate: true
    },
    drawer    : function() {
      let htmlElement = document.getElementsByTagName("html")[0];
      if (this.$vuetify.breakpoint.width < 1024) {
        if (htmlElement) {
          if (this.drawer) {
            htmlElement.style.overflow = "hidden";
          } else {
            htmlElement.style.overflow = "auto";
          }
        }
      } else {
        htmlElement.style.overflow = "auto";
      }
    },
    isExpanded: function(newState) {
      this.actionUpdateIsExpand(newState);
    }
  },
  methods: {
    ...mapActions("userData", [
      "updateContractorSelect",
      "updateUserName",
      "actionUpdateContractorName",
      "actionupdateContactForm",
      "actionUpdateIsStandard",
      "updatePlanType",
      "updateIsExistDbSource",
      "getIsShowNotificationPcaf",
      "updateIdDbSource",
      "updateMethodSetting",
      "updateIsExistPcaf",
    ]),
    ...mapActions("commonApp", ["actionUpdateIsExpand", "actionUpdateIsLoading"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen"]),
    ...mapActions("workflowData", ["getNotification"]),
    updateMenuChart(){
      if (this.currentUser.user.role_id === ROLE.APPROVAL || this.currentUser.user.role_id === ROLE.USER) {
        this.menuChart = this.menuChart.filter((item, index) => index !== 1 )
      }
    },
    checkIsReduction(isReductionTarget) {
      if (!isReductionTarget){
        this.menuChart = this.menuChart.filter(menu => menu.link !== ROUTES.PRODUCT_REDUCTION)
      }
    },
    checkEllipsisActive() {
      document.querySelectorAll('.overflow-eclipsis-text')?.forEach(element => {
        this.showEllipsisTip[element.id]= element.offsetWidth < element.scrollWidth
      });
    },
    isActivePath(listPathActive) {
      return listPathActive?.some(path => this.$route.path.includes(path))
    },
    toggleMenu() {
      this.isExpanded = !this.isExpanded;
      reLocateDashHeader(this.isExpanded);
      let headFix = document.getElementById('headFix');
      if(!headFix) return
      if (!this.isExpanded) {
        headFix.style.left = '73px';
      } else {
        headFix.style.left = '236px';
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    isEmissionDetail(menuIndex) {
      return (
        (this.$route.name === "EmissionsByBasePeriod" || this.$route.name === "EmissionsByBaseCategory") && !menuIndex
      );
    },
    isRegisterData(menuIndex) {
      return this.$route.name === "RegisterData" && menuIndex === 2;
    },
    isViewData(menuIndex) {
      return this.$route.name === "RegisterData" && menuIndex === 2;
    },
    handleChangeSelect(value) {
      this.updateContractorSelect(value);
    },
    handleActions(action) {
      if (action.id === 1) {
        this.$router.push({ path: ROUTES.SETTING });
      } else {
        logoutApi().then(() => this.$router.push({ path: "/auth/login" }).catch(() => {}));
      }
    },

    handleLogout() {
      logoutApi().then(() => this.$router.push({ path: "/auth/login" }).catch(() => {}));
      this.dialog = false;
    },
    logoutAndRedirectHome() {
      webStorage.remove(ACCESS_TOKEN);
      location.href = "/";
    },
    resetTimeExpiredApp() {
      webStorage.set(TIMEOUT_KEY, DEFAULT_TIMEOUT);
      webStorage.set(NOT_ACTIVE_TIMEOUT_KEY, NOT_ACTIVE_TIMEOUT);
    },
    handleLogoutAndRedirectHomeAfterExpired() {
      if (!this.checkAuth) {
        return;
      }

      this.idInternal = setInterval(() => {
        const time = Number.parseFloat(webStorage.get(TIMEOUT_KEY));
        if (time <= 0) {
          return this.logoutAndRedirectHome();
        }
        webStorage.set(TIMEOUT_KEY, time - TIME_CHECK_STEP);
      }, TIME_CHECK_INTERVAL);
    },
    handleLogoutAndRedirectLogin() {
      if (!this.checkAuth) {
        return;
      }
      this.activeInternal = setInterval(() => {
        const notActiveTime = Number.parseFloat(webStorage.get(NOT_ACTIVE_TIMEOUT_KEY));
        if (notActiveTime < 0) {
          clearInterval(this.activeInternal);
          return this.handleLogout();
        }
        webStorage.set(NOT_ACTIVE_TIMEOUT_KEY, notActiveTime - ACTIVE_CHECK_TIME_STEP);
      }, INTERVAL_CHECKING_TIME);
    },
    onDocumentEvent() {
      if (!this.checkAuth) {
        return;
      }
      document.addEventListener("click", this.callbackDocumentEvent);
      document.addEventListener("scroll", this.callbackDocumentEvent);
    },
    offDocumentEvent() {
      if (!this.checkAuth) {
        return;
      }
      document.addEventListener("click", this.callbackDocumentEvent);
      document.addEventListener("scroll", this.callbackDocumentEvent);
    },
    callbackDocumentEvent() {
      this.resetTimeExpiredApp();
    },
    
    async exportReport() {
      await getExportReport(this.contractor)
        .then(() => {
          getExportReport(this.contractor, true).then((res) => {
            this.getCsvExport(res);
          });
        })
        .catch((error) => {
          this.errorPopup   = true;
          this.errorMessage = error.message;
        });
    },
    async getCsvExport(content) {
      await this.getDataStartMonth()
      const fullDate  = moment().format("YYYYMMDDHHmmss");
      const excelName = `CARBONIX_GHG Report-${this.getDurationName()}_${fullDate}.xlsx`;
      // const url = window.URL.createObjectURL(new Blob([content], { type: 'application/vnd.ms-excel' }));
      // test download file in ios
      const url  = window.URL.createObjectURL(
        new Blob([content], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          if (this.currentUser.user.role_id !== res.role_id || !res.status) {
            this.handleLogout();
          }
          this.updateIsExistDbSource(res.existDbSource)
          this.getIsShowNotificationPcaf(res.is_notification_pcaf)
          this.updateIdDbSource(res.db_source_id)
          this.updatePlanType(res.plan_type)
          if(res.plan_type?.is_pcaf) {
            this.updateIsExistPcaf(true)
          } else {
            this.updateIsExistPcaf(false)
          }
          this.checkIsReduction(res.plan_type.is_reduction_target);
          // this.updateMethodSetting(!res.is_ghg_protocol)
          this.updateMethodSetting(res.plan_type?.is_pcaf ?? false)
          this.userName           = res.name;
          this.contractorName     = res.contractor.name;
          this.menuTop[0]["name"] = this.contractorName;
          this.menuTop[1]["name"] = this.userName;
          this.menuTop[0]["id"] = 'contractorName';
          this.menuTop[1]["id"] = 'userName';
        })
        .catch(() => {
        });
    },
    checkSettingMenu() {
      const setting = this.breadCrumbText.filter((obj) => obj.href === "/setting");
      return setting.length > 0;

    },
    isSetting(i) {
      const setting = this.breadCrumbText.filter((obj) => obj.href === "/setting");
      return setting.length > 0 && i === 0;

    },
    handleToTop() {
      setTimeout(() => {
        window.scrollTo({
          top     : 0,
          behavior: "smooth"
        });
      });
    },
    openMenu(isOpen) {
      this.drawer = !this.drawer;
      if (isOpen) {
        setTimeout(() => {
          this.closeIcon = true;
        }, 300);
      } else {
        this.closeIcon = false;
      }
    },
    toHomePage() {
      location.href = "/";
    },
    redirect(link) {
      this.handleToTop();
      if (link === process.env.VUE_APP_FAQS_LINK) {
        window.open(link, '_blank');
        return;
      }
      if (this.$route.path === link) {
        this.$router.go();
      } else {
        this.$router.push({ path: link });
      }
      if (link === "/contact") {
        this.actionupdateContactForm({
          selectedContactType: "",
          isCheck            : false,
          contactData        : "",
          isConfirmPage      : false
        });
      }
    },
    getDurationName() {
      const currentYear = new Date().getFullYear()
      const dateStart = {
        year: 2017,
        month: this.startMonth - 1,
      }
      const dateEnd = {
        year: this.startMonth === 1 ? currentYear : currentYear + 1,
        month: this.startMonth === 1 ? 11 : this.startMonth - 2,
      }
      const start = moment(dateStart).format("yyyyMM");
      const end = moment(dateEnd).format("yyyyMM");
      return `${start}-${end}`
    },
    async getDataStartMonth() {
      try {
        const res = await getStartMonth(this.$store.state.userData.contractor)
        this.startMonth = res.data?.start_month
      } catch (error) {
        console.warn(error);
      }
    },
    updateLastAction() {
      if (!this.checkAuth) {
        return;
      }
      this.lastActionInterval = setInterval(() => {
        updateLastAction({contractor_id: this.currentUser.user.contractor_id })
      }, 50000); // 1 minute
    },
    isActiveSubLink(item) {
      return this.$route.path === item?.sublink
    }
  },
  beforeDestroy() {
    this.$store.commit("dashboard/clearData");
    this.offDocumentEvent();
    clearInterval(this.idInternal);
    clearInterval(this.lastActionInterval);
    clearInterval(this.activeInternal);
    window.removeEventListener('storage', null)
  }
};
</script>

<style lang="scss">
$table: 1024px;

.bb-custome {
  border-bottom: 1px solid rgba(42, 42, 48, 0.1);
}

.tooltip-hover {
  position: relative;

  .tooltip-custom-info-mobile {
    display: none;
    width: 100%;
    max-width: 300px;
    position: absolute;
    font-size: 10px;
    z-index: 9999;
    padding: 10px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
    0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);

    p {
      padding: 0;
      margin: 0;
      width: 280px;
      word-break: break-all;
      white-space: normal;
    }
  }

  &:hover {
    .tooltip-custom-info-mobile {
      display: block;
    }
  }
}

.margin-top-4 {
  margin-top: 4px !important;
}

.toggle-btn {
  // bottom: 64px;
  bottom: 20px;
  right: 20px;

  width: 50px;
  height: 50px;
}

@media (max-width: $tablet) {
  .toggle-btn {
    right: 20px;
  }
}

@media (max-width: $desktop) {
  .toggle-btn {
    right: 20px;
  }
}

.v-application {
  position: absolute !important;
  width: 100%;
  background: linear-gradient(90deg, #efdfdc 0%, #eff5f5 24.82%, #eff5f5 99.93%) !important;
}

#inspire-mobile {
  background: linear-gradient(180deg, #efdfdc 7.72%, #eff5f5 16.84%, #eff5f5 99.93%) !important;

  .button-menu-sp {
    margin: 0 !important;
  }

  .content-main {
    padding: 0 20px !important;
    min-height: calc(100vh - 536px);
  }
  #Dashboard {
    padding: 0 8px !important;
  }

  .v-application--wrap {
    background: linear-gradient(180deg, #efdfdc 7.72%, #eff5f5 16.84%, #eff5f5 99.93%) !important;
    position: relative;
    width: 100%;
  }

  .header-sp {
    justify-content: start;
    font-size: 14px;
  }

  .menu-toggle-sp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 200;
  }

  .v-breadcrumbs__item {
    color: $goldMid;
  }

  .v-breadcrumbs__item--disabled {
    color: #404d50 !important;
  }

  aside.is-expanded {
    height: 100%;
    width: 100%;
    background: rgba(247, 247, 242, 0.8);
    backdrop-filter: blur(15px);
    .button {
      width: 100%;
    }

    .lable {
      width: 100%;
    }

    padding: 20px;
    overflow-y: auto;
    &.expanded-mobile {
      margin-top: 82px;
      height: calc(100vh - 82px - 70px);
      padding-bottom: 80px;
    }
  }

  .top {
    font-family: 'Century Gothic Pro';
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0.15em;
    color: $goldMid;
    text-transform: uppercase;
    padding: 24px 20px 20px;
    line-height: 38px;
    background: #F7F7F2;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  aside .menu-divider {
    display: none;
  }

  .theme--light.v-navigation-drawer {
    z-index: 100;
    background: rgba(247, 247, 242, 0.8);
    backdrop-filter: blur(15px);
    height: 100%;
    transition: opacity 0.5s, transform 0.5s;

    &--close {
      transition: all 0.5s ease 0s;
    }

    .v-navigation-drawer__border {
      background-color: #fff;
    }
  }

  .theme--light.v-app-bar--fixed {
    background: $monoOffWhite;
    box-shadow: 0 4.322566509246826px 7.315112113952637px rgba(160, 181, 186, 0.19),
      0 14.518600463867188px 24.5699405670166px rgba(160, 181, 186, 0.11), 0 65px 110px rgba(160, 181, 186, 0.08) !important;
  }

  .menu-toggle-wrap {
    justify-content: flex-end;
  }

  .v-toolbar__content,
  .v-toolbar__extension {
    padding: unset;
  }

  .v-toolbar__content {
    display: flex;
    flex-flow: column;
    height: 88px !important;

    .header-sp {
      width: 100%;
      height: 44px;
      padding: 8px 0px 8px 20px;
      display: flex;
      flex-flow: row;
      // justify-content: space-between;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);

      .header-wrapper {
        display: flex;
        align-items: center;
        margin-right: 0px;

        .logo-img {
          margin-right: 16px;
          width: 125px;
          cursor: pointer;
        }

        .undo-redo-action {
          height: 20px;
          width: 20px;
        }
      }

      .icons {
        margin-left: unset;
        gap: unset;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 28px;

          img {
            width: 20px;
            height: 16px;
          }
        }
      }
    }

    .breadcrumb-sp {
      width: 100%;
      display: flex;
      padding: 8px 0px 12px 20px;
    }
  }

  .v-main {
    padding: 88px 0px 0px !important;
  }

  .mn-top {
    width: 100%;
  }

  .mn-g1:first-child {
    padding-bottom: 4px;
  }

  .button.button-menu-sp {
    .menu-icon img {
      padding-left: 3px;
    }
  }
}

#menu-close-icon {
  .menu-close-hover {
    display: none;
    width: auto;
    height: auto;
  }
}

#menu-close-icon:hover {
  .menu-close-hover {
    display: block;
  }

  .menu-close {
    display: none;
  }
}

#menu-close-icon {
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 20px;
  border-radius: 8px;
  border: 1px solid $goldMid;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #f7f7f2;

  .menu-close-hover {
    display: none;
    width: auto;
    height: auto;
  }
}

#menu-close-icon:hover {
  .menu-close-hover {
    display: block;
  }

  .menu-close {
    display: none;
  }
}

#inspire {
  background: $monoOffWhite;

  .user-info__name {
    margin-right: 24px;
  }

  .option-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .v-menu__content {
    box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11),
      0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17),
      0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
    border: 1px solid $monoLight;
  }

  .option-list {
    display: flex;
    flex-direction: column;
    background: $monoOffWhite;
    padding: 8px;
    gap: 8px;
    min-width: 180px;

    .option-item {
      min-height: unset;
      padding: 6px 8px;
    }

    .option-item-link {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;

      .menu-icon {
        display: flex;

        .material-icons {
          width: 32px;
          height: 32px;
        }
      }

      .text {
        color: $monoBlack;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .header-wrapper-pc {
    transition: 0.1s ease-out;
    transition-duration: 0.1s;
    background: $monoOffWhite;
    box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
    0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
    0 65px 110px 0 rgba(160, 181, 186, 0.08);

    .v-toolbar__content,
    .v-toolbar__extension {
      padding: unset;
    }

    .v-toolbar__content {
      display: flex;
      flex-flow: column;
      height: unset !important;
    }

    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px 40px;
      border-bottom: 1px solid $dividerBlack;
      height: 44px;

      .header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: unset;
      }

      .undo-redo {
        display: flex;
        justify-content: center;
        align-items: center;

        .icons {
          margin-left: unset;
          gap: unset;
          display: flex;
          align-items: center;

          div {
            width: 40px;
            height: 28px;

            img {
              width: 20px;
              height: 16px;
            }
          }
        }

        .undo-redo-action {
          display: flex;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 0.05em;

          img {
            margin-right: 8px;
            width: 20px;
            height: 20px;
          }

          .status-success {
            color: $greenDark;
          }

          .status-saving {
            color: $monoDark;
          }
        }
      }

      .account-name {
        display: flex;

        div {
          display: flex;
          align-items: center;

          img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }

          &:first-child {
            margin-right: 24px;
          }
        }
      }

      .hidden {
        visibility: hidden;
      }
    }

    .breadcrumb-pc {
      padding: 8px 40px 8px;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    }
  }

  .v-main {
    padding-top: 84px !important;

    &.table-full-screen {
      padding-top: 0 !important;
    }
  }

  .content-main {
    min-height: calc(100vh - 536px);
    &.other-setting {
      min-height: calc(100vh - 652px);
    }
  }

  .user-info {
    position: fixed;
    right: 0;
    padding-right: 40px;
    top: 8px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__contractor {
      max-width: 270px;
      display: flex !important;
      align-content: center !important;
      margin-right: 24px;
      position: relative;

      .tooltip-custom-info {
        width: 100%;
        display: none;
        position: absolute;
        top: 30px;
        font-size: 11px;
        left: 40px;
        word-break: break-all;
        z-index: 9999;
        padding: 10px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
        0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }

      &:hover {
        .tooltip-custom-info {
          display: block;
        }
      }

      &--icon {
        margin-right: 14px;
      }
    }

    &__name {
      max-width: 180px;
      display: flex !important;
      align-content: center !important;
      position: relative;

      .tooltip-custom-info {
        width: 100%;
        display: none;
        position: absolute;
        top: 30px;
        font-size: 11px;
        left: 40px;
        z-index: 9999;
        padding: 10px;
        word-break: break-all;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
        0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }

      &:hover {
        .tooltip-custom-info {
          display: block;
        }
      }

      &--icon {
        margin-right: 14px;
      }
    }
  }
}

aside {
  display: flex;
  flex-direction: column;
  // background: linear-gradient(90deg, #efdfdc 0%, #eff5f5 24.82%, #eff5f5 99.93%);
  width: 80px;
  // Set variable footer height
  height: 100vh;
  overflow: hidden;
  color: var(--light);
  //transition: 2s ease-out;
  // transition-duration:0.1s;
  // -webkit-transition-duration:0.1s;
  // -ms-transition-duration:0.1s;
  // -moz-transition-duration:0.1s;
  padding: 20px;

  .logo {
    margin: -20px -20px 0 -20px;
    height: 86px;
    display: flex;
    min-height: 86px;

    .logo-img {
      margin: auto;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: $desktop) {
    .logo {
      margin: 0;
    }

    .menu-bottom {
      border-top: 0px solid rgba(42, 42, 48, 0.1) !important;
    }
  }

  .logo img:first-child {
    display: none;
  }

  .menu-title {
    position: static;
    width: 204px;
    // width: 48px;
    height: 16px;

    /* Labels/Menu Category */
    font-family: 'Century Gothic Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;

    /* identical to box height, or 145% */
    // text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $redDusty;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px;

    .menu-title-text {
      font-family: 'Century Gothic Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &.top {
      font-weight: 400;
      font-size: 28px;
      line-height: 38px;
      letter-spacing: 0.15em;
      color: $goldMid;
      height: 38px;
    }
  }

  .sustech-menu-title {
    h1 {
      font-size: 11px;
    }
  }

  .flex {
    flex: 1 1 0;
  }

  .menu-toggle-wrap {
    display: flex;
    justify-content: left;
    position: relative;

    .menu-icon {
      border-radius: 8px;
      border: 1px solid $goldMid;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 28px;
        height: 28px;
      }
    }

    .menu-toggle {
      transition: 0.2s ease-out;

      .material-icons {
        font-size: 2 ren;
        color: #404d50;
        transition: 0.2s ease-out;
      }

      &:hover {
        .material-icons {
          color: var(--primary);
        }
      }
    }
  }

  .menu-divider {
    width: 240px;
    margin-left: -16px;
    border: 0;
    border-top: 1px solid rgba(42, 42, 48, 0.1);
  }

  .menu {
    .button-mini-hover:hover {
      background: white !important;
    }

    .button {
      // width and height when mini state
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-radius: 4px;
      padding: 8px;
      transition: 0.2s ease-out;
      margin-top: 0px !important;

      .menu-toggle {
        height: 48px;
      }

      .menu-icon {
        height: 32px !important;
        width: 32px !important;
        display: flex;
        align-items: center;

        .material-icons {
          // position: absolute;
          margin: auto;
          // font-size: 2rem;
          // transition: 0.2s ease-out;
        }
      }

      .menu-icon-last {
        height: 20px !important;
        width: 32px !important;
        display: flex;
        align-items: center;

        .material-icons {
          // position: absolute;
          margin: auto;
          // font-size: 2rem;
          // transition: 0.2s ease-out;
        }

        & img:last-child {
          display: none;
        }
      }

      .menu-icon img:last-child {
        display: none;
      }

      .text {
        display: none;
        color: var(--light);
        transition: opacity 0.2s ease-in;
      }

      &:hover {
        background: #f7f7f2;
        box-shadow: 0px 2.194533586502075px 3.9900612831115723px 0px rgba(0, 0, 0, 0.07),
          0px 7.3709821701049805px 13.401785850524902px 0px rgba(0, 0, 0, 0.04), 0px 33px 60px 0px rgba(0, 0, 0, 0.03);
      }

      &.router-link-exact-active {
        background: $goldMid !important;

        .menu-icon img:first-child {
          display: none;
        }

        .menu-icon img:last-child {
          display: block;
        }

        .material-icons,
        .text {
          color: #ffffff;
        }
      }

      &.router-link-exact-active {
        .menu-icon img:first-child {
          display: none;
        }

        .menu-icon img:last-child {
          display: block;
        }
      }
    }

    &.bottom-btn {
      &:hover {
        background: transparent !important;
        .menu-toggle {
          box-shadow: 0px 2.194533586502075px 3.9900612831115723px 0px rgba(0, 0, 0, 0.07), 0px 7.3709821701049805px 13.401785850524902px 0px rgba(0, 0, 0, 0.04), 0px 33px 60px 0px rgba(0, 0, 0, 0.03);
          background: #F7F7F2;
        }

        .material-icons {
          color: #ffffff;
        }
      }
    }
  }

  &.is-expanded-mini {
    width: 80px !important;

    .menu-title {
      width: 52px;
      text-align: center;
    }
  }

  &.is-expanded {
    // Full side
    width: 236px;
    padding: 0px 16px;

    .logo img:last-child {
      display: none;
    }

    .logo img:first-child {
      display: block;
    }

    .button-mini {
      width: 50px !important;

      .text {
        display: none !important;
      }

      // margin: 7px 0 7px 0 !important;
    }

    .button {
      min-width: 100%;
    }

    .button,
    .lable {
      /* Menu/Btn */
      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      width: 204px;
      height: 48px;
      border-radius: 4px;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
      @media (max-width: 1024px) {
        .text { 
          max-width: fit-content !important;
        }
      }
      // margin: 7px 0 7px 0;
      .text {
        display: block;
        position: static;
        height: 24px;
        left: 56px;
        right: 8px;
        top: 12px;

        /* Labels/Regular */
        font-family: 'Source Han Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        /* identical to box height, or 171% */
        letter-spacing: 0.03em;

        /* Mono/Black */
        color: #404d50;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 12px;
        transition: 0.2s ease-in;
      }

      .number-status {
        display: flex;
        position: absolute;
        right: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 6px 6px;
        min-width: 19px;
        background: #132D39;
        border-radius: 50%;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 8px;
        text-align: right;
        letter-spacing: 0.03em;
        color: #FFFFFF;
      }
      .number-status-button-mini {
        display: flex;
        position: absolute;
        bottom: 1px;
        right: 2px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px 6px 6px;
        min-width: 19px;
        background: #132D39;
        border-radius: 50%;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 8px;
        text-align: right;
        letter-spacing: 0.03em;
        color: #FFFFFF;
      }
    }

    .button-expand {
      width: 48px !important;
      height: 28px !important;
      // padding: 0;
    }

    .menu-toggle {
      padding: 0;
      margin: 8px 0px !important;
      height: 28px;
      padding: 0px 8px;
    }

    .menu-toggle-wrap {
      .menu-toggle {
        padding: 0;
        margin: 8px;

        .material-icons {
          transform: rotate(-180deg);
        }
      }
    }

    .button-menu-sp {
      border-top: 1px solid rgba(42, 42, 48, 0.1);
      padding-left: unset;
      cursor: pointer;

      &:first-child {
        border-top: unset;
      }
    }

    .menu-bottom {
      border-top: 1px solid rgba(42, 42, 48, 0.1);
    }
  }

  // @media (max-width: 768px) {
  //   position: fixed;
  //   z-index: 99;
  // }
}

// Current
.v-main {
  font-family: $mainFont;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.custom {
  margin-bottom: -20px;
}

.v-navigation-drawer {
  @include tablet {
    z-index: 6;
  }
}

.wrap-header {
  width: 100%;
  gap: 20px;
}

.icons {
  margin-left: 30px;
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}

.header {
  padding-right: 10px;
}

.wrap-nav {
  width: 100%;
  height: 100%;

  &__logo {
    padding: 16px 0px;
    margin-bottom: 30px;
    text-align: center;

    h1 {
      color: #fff;
    }
  }

  &__content {
    padding: 20px 0 20px 20px;

    &__item {
      color: #ffb71c;
      padding-bottom: 30px;

      &__name-page {
        cursor: pointer;
        color: #fff;
        margin-top: 5px;
        margin-left: 18px;
        padding: 8px 3px;

        &:nth-child(1) {
          margin-top: 15px;
        }
      }
    }

    &__logout {
      position: absolute;
      bottom: 0px;
      color: #fff;
      padding: 20px;
    }
  }
}

.text-header {
  font-family: 'Source Han Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #404d50;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.text-menu-mini-pc {
  font-family: 'Source Han Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #404d50;
  margin: 0px 16px;
}

.header-fix-height {
  height: 173px !important;
}

.overflow-eclipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button {
  cursor: pointer;
}
</style>


<style scoped lang="scss">
#inspire-mobile {
  .close-btn-block {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px 20px;
    align-items: flex-start;
    background-color: #F7F7F2;
    width: 100%;
    z-index: 102;
  }
}

.disable-menu {
  pointer-events: none;
  opacity: 0.5;
}
</style>