<template>
  <div class="comment-dialog-data-table" :class="{ disabled: disabledUndoRedoAction() }">
    <div class="undo-redo">
      <div class="icon icon-undo" :class="allowActionUndo() && 'undo-redo-hover tooltip-hover'" @click="handleUndo">
        <img
          v-if="!allowActionUndo() || !statusAction"
          class="disable-pointer"
          src="@/assets/icons/undo-redo/undo-disabled.svg"
          alt=""
        />
        <img class="action" v-else src="@/assets/icons/undo-redo/undo.svg" alt="" />
        <div class="tooltip-custom-info-mobile">
          <p>{{ $t('register_data.button_undo') }}</p>
        </div>
      </div>
      <div class="icon icon-redo" :class="allowActionRedo() && 'undo-redo-hover tooltip-hover'" @click="handleRedo">
        <img
          v-if="!allowActionRedo() || !statusAction"
          class="logo-img"
          src="@/assets/icons/undo-redo/redo-disabled.svg"
          alt=""
        />
        <img v-else class="action" src="@/assets/icons/undo-redo/redo.svg" alt="" />
        <div class="tooltip-custom-info-mobile">
          <p>{{ $t('register_data.button_redo') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
export default {
  data() {
    return {
      disabledUndo: true,
      disabledRedo: true,
      enableInRoute: [
        ROUTES.USER_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.GROUP_ENTERPRISE_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA,
        '/products/register',
        ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.VIEWER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
      ],
      enableActionRegisterData: [ROUTES.EMISSIONS + '/' + ROUTES.REGISTER_DATA],
    };
  },
  props: {
  },
  computed: {
    ...mapState('commonApp', ['statusAction']),
    ...mapState('actionsTable', ['statusBtn']),
  },

  mounted() {
    this.disabledUndoRedoAction();
  },

  methods: {
    ...mapActions('actionsTable', ['actionStartUpdate']),

    allowActionUndo() {
      return this.statusBtn.undo;
    },

    allowActionRedo() {
      return this.statusBtn.redo;
    },

    disabledUndoRedoAction() {
      if (
        this.enableInRoute.includes(this.$route.path) ||
        this.$route.name === 'RegisterData' ||
        this.$route.name === 'NewRegisterData'
      ) {
        return false;
      }
      return true;
    },

    handleUndo() {
      if (this.allowActionUndo() && this.statusAction) {
        this.actionStartUpdate({ undo: true, redo: false });
      }
    },

    handleRedo() {
      if (this.allowActionRedo() && this.statusAction) {
        this.actionStartUpdate({ undo: false, redo: true });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-dialog-data-table {
  justify-content: space-between;
  margin-left: auto;
  display: flex;
  align-items: flex-start;

  .undo-redo {
    cursor: default !important;
    display: flex;
    align-items: center;
    margin-right: 0px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 28px;
      margin-right: 0 !important;

      &.icon-undo {
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 4px 0px 0px 4px;
        border-right: 0;
      }
      &.icon-redo {
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  .undo-redo-hover {
    &:hover {
      background: $goldLight;
      border-radius: 4px;
      cursor: pointer;
      .action {
        -webkit-filter: brightness(0) invert(1);
        -moz-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }
    }
  }

  .button-comment-dialog {
    box-sizing: border-box;
    padding: 6px 8px;
    height: 28px;
    background: #f7f7f2;
    border: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    flex: none;
    flex-grow: 0;
    min-width: 45px;
    .v-btn__content {
      .comment-dialog-img {
        margin-right: 6px;
      }
      .comment-dialog-img-action {
        margin-right: 6px;
        display: none;
      }
      .comment-dialog-img-disabled {
        margin-right: 6px;
        display: none;
      }
      .comment-dialog-text {
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #404D50;
      }
    }
    &:hover {
      background: #0072a3;
      .v-btn__content {
        .comment-dialog-img {
          display: none;
        }
        .comment-dialog-img-action {
          display: block;
        }
        .comment-dialog-img-disabled {
          display: none;
        }
        .comment-dialog-text {
          color: #ffffff;
        }
      }
    }
  }
  .button-comment-dialog.disabled {
    pointer-events: none;
    background: $monoLight;
    .comment-dialog-text {
      color: $monoMid;
    }
    .comment-dialog-img-disabled {
      display: block;
    }
    .comment-dialog-img {
      display: none;
    }
  }

  &.disabled {
    display: none;
  }
}
.undo-redo-action {
  order: 0;
  display: flex;
}

.tooltip-custom-info-mobile {
    display: none;
}
.tooltip-hover {
  position: relative;

  .tooltip-custom-info-mobile {
    justify-content: left;
    background: $monoWhite;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    width: fit-content;
    max-width: max-content;
    position: absolute;
    left: 0px;
    top: 30px;
    bottom: unset;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);

    p {
      padding: 0;
      margin: 0;
      width: fit-content;
      word-break: keep-all;
      white-space: normal;
    }
  }

  &:hover {
    .tooltip-custom-info-mobile {
      display: block;
    }
  }
}

@include desktop {
  .undo-redo-wrapper {
    display: flex;
    flex-direction: row;
    height: 24px;

    &.disabled {
      display: none;
    }
  }
  .undo-redo-action {
    order: 0;
    display: flex;
  }
}
</style>
