import request from '.././base/request';
const CFP_PRODUCT = '/cfp-product/';
const CFP_PRODUCTS_EMISSIONS = 'emission/cfp-product/';
const CFP_PROCESS_EMISSIONS = 'process-emission/cfp-product';
const CFP_PROCESS_EMISSIONS_SUPPLIER = 'cfp-product-supplier';
const CFP_WORKFLOW_DATA_SUPPLIER = '/cfp-workflow-data-supllier';
const CFP_PRIMARY_LINKED = 'cfp-primary-linked';

import store from '@/store';
export const getEmissionProductByProcessApi = () => {
  return [];
};

export const actionProductWorkflow = (data, method = 'post', opstions) => {
  store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
  return request[method](CFP_PRODUCT, data, {params: opstions})
    .then((res) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      return res;
    })
    .catch((err) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      throw err;
    });
};

export const getDetailProduct = (id) => {
  return request.get(`${CFP_PRODUCT}${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const getProductList = (contractorId) => {
  return request.get(CFP_PRODUCT, {params: contractorId})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const duplicateProduct = (productId) => {
  return request.put(`${CFP_PRODUCT}${productId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const getLogProducts = ({id, contractorId}) => {
  return request.get(`${CFP_PRODUCT}logs/${id}`, {params: contractorId})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const getLogEmissions = ({id, contractorId}) => {
  return request.get(`${CFP_PRODUCTS_EMISSIONS}logs/${id}`, {params: contractorId})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const deleteProduct = (id) => {
  return request.delete(`${CFP_PRODUCT}${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const actionNotificationProduct = (data, method = 'post', opstions) => {
  return request[method](`${CFP_PRODUCT}notification`, data, {params: opstions})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}
export const actionNotificationEmissionProduct = (data, method = 'post', opstions) => {
  return request[method](`${CFP_PRODUCTS_EMISSIONS}notification`, data, {params: opstions})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}
export const actionEmissionsProduct = (method = 'post', id) => {
  store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
  return request[method](`${CFP_PRODUCTS_EMISSIONS}${id}`)
    .then((res) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      return res;
    })
    .catch((err) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      throw err;
    })
}

export const actionProcessEmissionProduct = async (method = 'post', params, processId) => {
  store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
  try {
    let url = `${CFP_PROCESS_EMISSIONS}`;
    let options = {};

    if (method === 'get') {
      url += `/${params}`;
      options = { method };
    } else if (method === 'put') {
      url += `/${processId}`;
      const body = new FormData();
      const payloadBlob = new Blob([JSON.stringify(params)], { type: 'application/json' });
      body.append('data', payloadBlob);
      body.append('_method', 'put');
      options = { method: 'post', body };
    } else {
      options = { method, body: params };
    }

    const res = await request[options.method](url, options.body || params);
    store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    return res;
  } catch (err) {
    store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
    throw err;
  }
};


export const getProductEmissionsList = (productId) => {
  return request.get(`${CFP_PRODUCTS_EMISSIONS}list-emissions/${productId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const getDbProcessEmission = (params) => {
  return request
    .get(`/db-process-emission`, { params: params })
    .then((res) => {
      let masterDb = [];
      res.data.map((item) => {
        masterDb.push({
          ...item,
          value_source: formatValueSourceNumber(item.value_source),
          source: item.source.trim(),
          item_name: item.item_name ? item.item_name.trim() : item.source,
          idType: `${item.id}-${item.type}`
        });
      });

      let idList = [];
      res.data.forEach((item) => {
        idList.push(item.id);
      });

      return { masterDb, idList };
    })
    .catch((err) => {
      throw err;
    });
}

function formatValueSourceNumber(number) {
  const subRange = number?.toString()?.includes('-') ? 26 : 25
  return number?.toString()?.slice(0, subRange)
}

export const actionEmissionsProductList = (status) => {
  return request.get(CFP_PRODUCTS_EMISSIONS, {params: status})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const emissionsProductWorkflow = (data, method = 'put', options) => {
  store.dispatch('commonApp/actionUpdateIsLoading', true, { root: true });
  return request[method](CFP_PRODUCTS_EMISSIONS, data, {params: options})
    .then((res) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      return res;
    })
    .catch((err) => {
      store.dispatch('commonApp/actionUpdateIsLoading', false, { root: true });
      throw err;
    })
}

export const deleteEmissionsProduct = (id) => {
  return request.delete(`${CFP_PRODUCTS_EMISSIONS}${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
}

export const getListGroupProductByName = (contractorId) => {
  return request.get('/cfp-product/list-group-by-name', {params: contractorId})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    }
  )
}

// Primary Data Merge - Register emission product

export const workflowDataSupplierApi = (params) => {
  return request
    .get(CFP_WORKFLOW_DATA_SUPPLIER, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateFormPrimaryData = (data) => {
  return request
    .put(CFP_PROCESS_EMISSIONS_SUPPLIER, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateFormPrimaryDataLinked = (data) => {
  return request
    .put(CFP_PRIMARY_LINKED, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateNotification = (data) => {
  return request
    .put(`${CFP_WORKFLOW_DATA_SUPPLIER}/notification`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNameCodeProductApprovedList = () => {
  return request.get(`${CFP_PROCESS_EMISSIONS_SUPPLIER}/list-name-code`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    }
  );
}

export const getNoListByProductName = (params) => {
  const queryString = new URLSearchParams(params).toString();
  return request.get(`${CFP_PROCESS_EMISSIONS_SUPPLIER}/list-lot-number?${queryString}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    }
  );
}

export const getBoundaryProcessByLotId = (id) => {
  return request.get(`${CFP_PROCESS_EMISSIONS_SUPPLIER}/list-boundary-and-process/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    }
  );
};

export const createFormPrimary = (data) => {
  return request
    .post(CFP_PROCESS_EMISSIONS_SUPPLIER, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
  
export const getListFormCfpSupplierApi = () => {
  return request
    .get(CFP_PROCESS_EMISSIONS_SUPPLIER)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDetailFormCfpSupplierApi = (id) => {
  return request
    .get(`${CFP_PROCESS_EMISSIONS_SUPPLIER}/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateFormPrimaryById = (id, data) => {
  return request
    .put(CFP_PROCESS_EMISSIONS_SUPPLIER + `/${id}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPrimaryDetail = (processId) => {
  return request.get(`/cfp-workflow-data-supllier/${processId}`)
    .then((res) => {
    return res;
  })
  .catch((error) => {
    throw error;
  });;
};

export const approvalSupplier = (action, data) => {
  return request.put(`/cfp-workflow-data-supllier?action=${action}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    })
};

export const getListCommentHistoryProduct = (params) => {
  return request
    .get(`/cfp-workflow-data-supllier/comment/${params}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
