export const STATUS = [
  {
    id: 0, //draft
    name: 'status.status_draft',
    color: 'mono-black',
    color_text: 'normal'
  },
  {
    id: 1, //submit
    name: 'status.status_submit',
    color: 'blue',
    color_text: 'blue-text'
  },
  {
    id: 2, //return
    name: 'status.status_return',
    color: 'red',
    color_text: 'red-text'
  },
  {
    id: 3, //dai dien return
    name: 'status.status_reprent_return',
    color: 'red',
    color_text: 'red-text'
  },
  {
    id: 4, //approved
    name: 'status.status_approved',
    color: 'light-green',
    color_text: 'mono-text'
  },
  {
    id: 5, // đại diện approved
    name: 'status.status_reprent_approved',
    color: 'light-green',
    color_text: 'mono-text'
  },
  {
    id: 6, // Is cancel submitted
    name: 'status.status_cancel_submmited',
    color: 'blue',
    color_text: 'blue-text'
  },
  // Van la submit nhung view cho approver/admin
  {
    id: 8, // Dang cho approval
    name: 'status.status_submit_non_user',
    color: 'red',
    color_text: 'normal'
    
  },
  {
    id: 9, // template
    name: 'status.status_template',
    color: 'light-green',
    color_text: 'normal'
  },
  // Dang cancel submit nhung view cho approver/admin
  {
    id: 10,
    name: 'status.status_cancel_submmited_non_user',
    color: 'blue',
    color_text: 'blue-text'
  }

];
export const STATUS_DRAFT = 0;
export const STATUS_SUBMIT = 1;
export const STATUS_RETURN = 2;
export const STATUS_REPRENSENT_RETURN = 3;
export const STATUS_CANCEL_SUBMMITED = 6;
export const STATUS_SUBMIT_ADMIN = 7;
export const STATUS_SUBMIT_TO_APPROVAL = 8;
export const STATUS_FOR_TEMPLATE = 9;

export const STATUS_FIELD = {
  STATUS_DRAFT: 0,
  STATUS_SUBMIT: 1,
  STATUS_RETURN: 2,
  STATUS_REPRENSENT_RETURN: 3,
  STATUS_APPROVED: 4,
  STATUS_REPRENSENT_APPROVED: 5,
  STATUS_CANCEL_SUBMMITED: 6,
  STATUS_SUBMIT_NON_USER: 8,
  STATUS_CANCEL_SUBMMITED_NON_USER: 10,
  STATUS_TEMPLATE: null,
}

export const EMAIL_VALID = {
  INVALID_EMAIL: 'user_management.status_status_confirm',
  CONFIRMING_EMAIL: 'user_management.status_status_confirming',
  CONFIRMED_EMAIL: 'user_management.status_status_confirmed'
}

export const STATUS_CONFIRM_EMAIL = [
  {
    status: null, //status confirm
    name: 'user_management.status_status_confirm',
    color_text: 'btn_confirm'
  },
  {
    status: false, //status confirming
    name: 'user_management.status_status_confirming',
    color_text: 'btn_confirming'
  },
  {
    status: true, //status confirmed
    name: 'user_management.status_status_confirmed',
    color_text: 'btn_verified'
  }
]