import moment from 'moment';
import i18n from '@/lang/i18n';

export const formatDateTime = (dateTime) => {
  if (dateTime) {
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/M/DD HH:mm ') + 'JST';
  }
};

export const formatDateTimeDataTable = (dateTime,noHour = false) => {
  if (dateTime) {
    if (noHour) {
      return moment.utc(dateTime).add(9, 'hours').format('YYYY/MM/DD');
    }
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
  }
};

export const formatNumber = (number, numberDecimals = 2) => {
  if (!number) {
    number = 0;
  }
  number = Number(number);
  if (Number.isInteger(number)) {
    return number.toLocaleString('ja-JP') + '.00';
  } else {
    const numberArr = number.toFixed(numberDecimals).split('.');
    const decimals = numberArr[1];
    return Number(numberArr[0]).toLocaleString('ja-JP') + '.' + decimals;
  }
};
export const formatDate = (value) => {
  const dateFormat = i18n.locale === 'en'
    ? 'YYYY-MM-DD' // Format for English
    : 'YYYY年M月D日 (ddd)'; // Format for other locales (e.g., Japanese)

  if (value != i18n.t('contractor.placeholder_select_date')) {
    value = String(value).split('-');
    value = moment(String(`${value[0]}/${value[1]}/${value[2]}`))
      .locale(i18n.locale)
      .format(dateFormat);
  }
  return value;
}

export const validRangeDate = (fromDate, toDate) => {
  // check valid date
  if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
    return true;
  }
  // check date range
  return !moment(toDate).isBefore(moment(fromDate));
};


export default {
  formatDateTime,
  formatNumber,
  formatDate,
  validRangeDate
};
